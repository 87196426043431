import api from './api';

const FeedApi = {
  getFeed(params: {page: number; limit: number}) {
    return api.get('/feed', {
      params,
    });
  },
  getRandomFeed(params: {limit: number}) {
    return api.get('/explore', {
      params,
    });
  },
};

export default FeedApi;
