/**
 * Check access permission to auth routes
 */
import {Route} from 'vue-router';
import {vxm} from '@/store';

export function checkAccessMiddleware(to: Route, from: Route, next: Function) {
  const isAuthRoute = to.matched.some((item) => item.meta.isAuth);
  const isLoginRoute = to.matched.some((item) => item.meta.isLogin);
  if (isAuthRoute && !vxm.user.accessToken) {
    return next({path: '/login'});
  }
  if (isLoginRoute && vxm.user.accessToken) {
    return next({path: '/home'});
  }
  return next();
}

export function setPageTitleMiddleware(to: Route, from: Route, next: Function) {
  const pageTitle = to.matched.find((item) => item.meta.title);

  if (pageTitle) {
    window.document.title = pageTitle.meta.title;
  }
  return next();
}

export function searchHashTags(to: Route, from: Route, next: Function) {
  if (to.path.includes('#')) {
    to.path.replaceAll('#', '%23');
  }
  return next();
}
