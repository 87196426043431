






import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class NoContent extends Vue {
  @Prop({type: String, default: 'No content to display...\nTry to add some!'}) readonly message!: string;
}
