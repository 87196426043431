import api from './api';

const FavoritesApi = {
  favor(data: {postId: string}) {
    return api.post('/favorites', data);
  },
  disfavor(data: {postId: string}) {
    return api.delete('/favorites', {data});
  },
  getFavs() {
    return api.get('/favorites/user');
  },
};

export default FavoritesApi;
