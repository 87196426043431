


















































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {mapState} from 'vuex';
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import {vxm} from '@/store';
import Loader from '@/components/Loader.vue';
import AutosuggestModal from '@/components/modals/AutosuggestModal.vue';
import VideoTrimmer from '@/components/modals/VideoTrimmer.vue';
import ProfileActivityPreferencesModal from '@/components/modals/ProfileActivityPreferencesModal.vue';
import {MediaTypes} from '@/constants/mediaTypes';
import hashtagValidationRegex from '@/constants/hashtagValidationRegex';
import maxHashtagLength from '@/constants/maxHashtagLength';
import imageTypes from '@/constants/imageTypes';
import imageQuantityUploadLimit from '@/constants/imageQuantityUploadLimit';
import hashtagQuantityUploadLimit from '@/constants/hashtagQuantityUploadLimit';
import {imageSizeLimitInBytes, imageSizeLimitInMB} from '@/constants/imageSizeLimit';
import {videoDurationLimitFormatted} from '@/constants/videoDurationLimit';
import {
  untrimmableVideoSizeLimitBytes,
  untrimmableVideoSizeLimitMB,
  videoSizeLimitBytes,
  videoSizeLimitMB,
} from '@/constants/videoSizeLimit';
import maxMessageLength from '@/constants/maxMessageLength';
import heicValidationRegex from '@/constants/heicValidationRegex';
import {videoTypes, videoTypesToTrim, videoTypesUntrimmable} from '@/constants/videoTypes';
import mentionQuantityUploadLimit from '@/constants/mentionQuantityUploadLimit';
import mentionValidationRegex from '@/constants/mentionValidationRegex';
import {SuggestionTypes} from '@/constants/suggestionTypes';
import {UserDataInterface} from '@/types/UserDataInterface';
import PostInterface from '@/types/PostInterface';
import debounce from '@/utils/debounce';
import clickInside from '@/utils/clickInside';
import clickOutside from '@/utils/clickOutside';
import {hasRestrictedWords, matchRestrictedWord} from '@/constants/restrictedWordsValidation';

@Component({
  name: 'CreateAndEditPost',
  components: {
    Loader,
    AutosuggestModal,
    CoolLightBox,
    VideoTrimmer,
    ProfileActivityPreferencesModal,
  },
  directives: {
    clickInside,
    clickOutside,
  },
  computed: {
    ...mapState('user', ['data']),
  },
})
export default class CreatePost extends Vue {
  @Prop({default: false}) readonly isEditing!: boolean;
  @Prop() readonly post!: PostInterface | null;
  @Prop() readonly inModal!: boolean;

  data!: UserDataInterface;
  hashtagsFromHashtagsInput = new Set<string>();
  hashtagsFromMessageInput = new Set<string>();
  hashtagChanges = 1;
  media = [] as string[];
  videoPlaying = false;
  mediaType = '';
  mediaErrorList = {
    ImageFormatError: 'Wrong image format. Only jpeg, png, heic and gif formats are allowed.',
    ImageSizeError: `Image size should be less than ${imageSizeLimitInMB}MB.`,
    ImageQuantityError: `Please choose up to ${imageQuantityUploadLimit} images in total.`,
    ImageFileError: 'Image file error. Check the file or choose another one.',
    VideoFormatError: 'Wrong video format. Only mp4, webm, avi, mov and m4v formats are allowed.',
    VideoDurationError: `Video should be less than ${videoDurationLimitFormatted} minutes long.`,
    VideoSizeError: `Video size should be less than ${videoSizeLimitMB}MB.`,
    UntrimmableVideoSizeError: `Video size should be less than ${untrimmableVideoSizeLimitMB}MB.`,
    VideoFileError: 'Video file error. Check the file or choose another video.',
    FileUploadError: 'Error happened while uploading a file. Check your file or try again later.',
  };
  mediaErrorMessages = [] as string[];
  mediaErrors = false;
  imageThumbnails = [] as string[];
  imageBlobs = [] as string[];
  videoPreview = '';
  videoPreviewThumbnail = '';
  message = '';
  formattedMessage = '';
  editingMessage = true;
  messageError = false;
  messageErrorMessage = '';
  messageErrorList = {
    MessageLengthError: `Message should be up to ${maxMessageLength} symbols long.`,
    RestrictedWordsError: `Input contains restricted word: `,
  };
  messageSuggestionType = SuggestionTypes.Hashtags;
  hashtagsEditing = false;
  hashtagInput = '';
  hashtagError = false;
  hashtagErrorMessage = '';
  hashtagErrorList = {
    HashtagQuantityError: `Maximum of ${hashtagQuantityUploadLimit} hashtags is allowed.`,
    HashtagLengthError: `Hashtags should be up to ${maxHashtagLength} symbols long.`,
    HashtagValidationError: 'Hashtags should be separated by spaces.',
    RestrictedWordsError: `Input contains restricted word: `,
  };
  MediaTypes = MediaTypes;
  showDropZone = false;
  files: File[] = [];
  videoPreviewLimit = {
    start: 0,
    end: 0,
  };

  resizeDebounceHandler = () => {
    /* Will be replaced */
  };
  hashtagSuggestOnHashtagsDebounceHandler = () => {
    /* Will be replaced */
  };

  dropHandler(ev: DragEvent) {
    if (ev.dataTransfer) {
      if (ev.dataTransfer.items) {
        for (let i = 0; i < ev.dataTransfer.items.length; i++) {
          if (ev.dataTransfer.items[i].kind === 'file') {
            this.prepareDroppedFile(ev, ev.dataTransfer.items[i].getAsFile() as File);
          }
        }
      } else {
        for (let i = 0; i < ev.dataTransfer.files.length; i++) {
          this.prepareDroppedFile(ev, ev.dataTransfer.files[i]);
        }
      }
    }
    this.showDropZone = false;
  }

  prepareDroppedFile(event: DragEvent, file: File) {
    const type = file.type.split('/')[0];
    if (type === 'video') {
      this.onVideoPicked(event, file);
    } else if (type === 'image') {
      const dt = new DataTransfer();
      dt.items.add(file);
      this.addImage(event, dt.files);
    }
  }

  dragOverHandler() {
    this.showDropZone = true;
  }

  messageSuggestionQuery = '';
  showSuggestionsOnMessageInput = false;
  hashtagOnHashtagsSuggestionQuery = '';
  showSuggestionsOnHashtagInput = false;
  focusedSuggestionOnMessages = 0;
  focusedSuggestionOnHashtags = 0;
  imageViewerIndex = null as null | number;
  isTrimming = false;
  videoToTrimUrl = '';
  videoToTrimFile = {} as File;
  videoTypes = videoTypes;
  mentions = new Map() as Map<string, {username: string; _id: string}>;
  mentionsArray = [] as {username: string; _id: string}[];
  mentionErrorList = {
    MentionQuantityError: `Maximum of ${mentionQuantityUploadLimit} mentions is reached. Last mention wasn't added.
    Delete mentions to free space for the new ones.`,
    MentionValidationError: 'Mentions should be separated by spaces.',
  };
  messageSuggestDebounceHandler = () => {
    /* Will be replaced */
  };
  pickFirstSuggestionOnMessage = false;
  pickFirstSuggestionOnHashtags = false;
  disableSuggestionsOnHashtags = false;
  playerError = false;
  showProfileActivityPreferencesModal = false;

  get videoUrl(): string {
    return URL.createObjectURL(this.files[0]) + `#t=${this.videoPreviewLimit.start},${this.videoPreviewLimit.end}`;
  }
  get cannotSubmit(): boolean {
    return (!this.media.length && !this.files.length && !this.message.length) || this.anyErrors;
  }

  get anyErrors(): boolean {
    return this.hashtagError || this.messageError || this.mediaErrors;
  }

  get hashtags(): Set<string> {
    if (!this.hashtagChanges && !this.hashtagsFromHashtagsInput.size && !this.hashtagsFromMessageInput.size) {
      return new Set<string>();
    }
    const combined = this.hashtagsFromHashtagsInput.size ? this.hashtagsFromHashtagsInput : new Set<string>();
    if (this.hashtagsFromMessageInput.size) {
      for (const hashtag of this.hashtagsFromMessageInput) {
        combined.add(hashtag);
      }
    }
    return combined;
  }
  get videoPlayer() {
    return this.$refs.videoPlayer as HTMLVideoElement;
  }
  get messageLengthError(): boolean {
    return this.message.length > maxMessageLength;
  }

  get userAvatar(): string {
    return this.data.mediaId && this.data.mediaId.link
      ? this.data.mediaId.link.medium || this.data.mediaId.link.origin || require('@/assets/icons/avatar-default.svg')
      : require('@/assets/icons/avatar-default.svg');
  }

  get title(): string {
    return this.isEditing ? 'Edit post' : 'Create new post';
  }

  get submitMessage(): string {
    return this.isEditing ? 'Save post' : 'Create post';
  }

  @Watch('mediaErrorMessages.length')
  onMediaErrors(newVal: number, oldVal: number) {
    if (this.mediaErrorMessages.length) {
      setTimeout(() => {
        this.mediaErrorMessages.splice(0, newVal - oldVal);
      }, 5000);
    }
  }

  mounted() {
    if (vxm.user.postPreferencesUnset) {
      this.showProfileActivityPreferencesModal = true;
      return;
    }
    this.resizeDebounceHandler = debounce(this.onResize, 300);
    window.addEventListener('resize', this.resizeDebounceHandler, false);
    window.addEventListener('keyup', (e) => {
      this.$nextTick();
      if (e.code === 'ArrowDown') {
        this.onArrowNavigation(e, 1);
      } else if (e.code === 'ArrowUp') {
        this.onArrowNavigation(e, -1);
      }
    });
    this.hashtagSuggestOnHashtagsDebounceHandler = debounce(this.onHashtagInputHashtagTyping, 300);
    this.messageSuggestDebounceHandler = debounce(this.onMessageInputTyping, 300);
    this.pickOnLoadAction();
    if (this.isEditing && this.post !== null) {
      this.setPostToEdit();
    }
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeDebounceHandler, false);
    window.removeEventListener('keyup', (e) => {
      this.$nextTick();
      if (e.code === 'ArrowDown') {
        this.onArrowNavigation(e, 1);
      } else if (e.code === 'ArrowUp') {
        this.onArrowNavigation(e, -1);
      }
    });
  }
  playVideo() {
    if (this.videoPlaying) {
      this.videoPlayer.pause();
      this.videoPlaying = false;
    } else {
      this.videoPlayer.play();
      this.videoPlaying = true;
    }
  }
  pickOnLoadAction(): void {
    if (vxm.files.files && vxm.files.files instanceof FileList) {
      this.addImage(new Event('event'), vxm.files.files as FileList);
    } else if (vxm.files.file && vxm.files.file instanceof File) {
      this.onVideoPicked(new Event('event'), vxm.files.file as File);
    } else {
      const input = document.getElementById('message-textarea') as HTMLInputElement;
      if (input) {
        input.focus();
      }
    }
  }

  async onTextareaFocus(input: string) {
    if (input === 'hashtags-textarea') {
      this.hashtagsEditing = true;
    } else if (input === 'message-textarea') {
      this.editingMessage = true;
    } else {
      return;
    }
    await this.$nextTick();
    const el = document.getElementById(input) as HTMLInputElement;
    if (el) {
      el.focus();
      el.style.height = '';
      el.style.height = `${el.scrollHeight}px`;
    }
  }

  async onTextareaInput(input: string, value?: string) {
    if (this.editingMessage && input === 'message-textarea') {
      this.message = value || '';
      if (this.messageLengthError) {
        this.messageErrorMessage = this.messageErrorList.MessageLengthError;
        this.messageError = true;
        this.message = this.message.slice(0, maxMessageLength);
      } else if (!hasRestrictedWords(this.message)) {
        this.messageErrorMessage = this.messageErrorList.RestrictedWordsError + matchRestrictedWord(this.message);
        this.messageError = true;
      } else {
        this.messageError = false;
      }
      this.messageSuggestDebounceHandler();
    } else if (input === 'hashtags-textarea') {
      this.disableSuggestionsOnHashtags = false;
      this.hashtagInput = value || '';
      this.hashtagSuggestOnHashtagsDebounceHandler();
    }
    await this.$nextTick();
    const el = document.getElementById(input) as HTMLInputElement;
    if (el) {
      el.style.height = '';
      el.style.height = `${el.scrollHeight}px`;
      el.blur();
      el.focus();
    }
  }

  onMessageInputBlur(): void {
    if (!this.editingMessage) {
      return;
    }
    this.messageError = false;
    if (this.messageLengthError) {
      this.messageErrorMessage = this.messageErrorList.MessageLengthError;
      this.messageError = true;
    } else if (!hasRestrictedWords(this.message)) {
      this.messageErrorMessage = this.messageErrorList.RestrictedWordsError + matchRestrictedWord(this.message);
      this.messageError = true;
    }
    if (this.setHashtagsFromMessageInput() || this.mentions.size) {
      this.styleMessageInput();
      if (this.mentions.size) {
        this.actualizeMentions();
      }
      this.mentionsArray = Array.from(this.mentions.values());
    } else {
      this.formattedMessage = this.message;
    }
    if (!this.messageError) {
      this.editingMessage = false;
    }
  }

  setHashtagsFromMessageInput(): boolean {
    this.hashtagError = false;
    const hashtags = this.message
      .trim()
      .split(/\s+/)
      .filter((word) => hashtagValidationRegex.test(word));
    if (!hashtags.length) {
      this.hashtagsFromMessageInput = new Set<string>();
      return false;
    }
    if (hashtags.length > hashtagQuantityUploadLimit) {
      this.messageErrorMessage = this.hashtagErrorList.HashtagQuantityError;
      this.messageError = true;
      return false;
    }
    this.hashtagsFromMessageInput = new Set<string>(hashtags);
    return true;
  }

  styleMessageInput(): void {
    let string = this.message;
    this.hashtagsFromMessageInput.forEach((hashtag) => {
      string = string.replaceAll(hashtag, `<span class="hashtag">${hashtag}</span>`);
    });
    this.mentions.forEach((mention) => {
      string = string.replaceAll(`@${mention.username}`, `<span class="hashtag">@${mention.username}</span>`);
    });
    this.formattedMessage = string;
  }

  openFileDialogueWindow(input: string): void {
    const el = document.getElementById(input) as HTMLElement;
    if (el) {
      el.click();
    }
  }

  addImage(event: Event, passedFiles?: FileList): void {
    let files: FileList;
    let target = {} as HTMLInputElement;
    if (passedFiles) {
      files = passedFiles;
      vxm.files.removeFiles();
    } else {
      target = event.target as HTMLInputElement;
      files = target.files as FileList;
    }
    const errorMessages = new Set<string>();
    if (!files.length) {
      target.value = '';
      vxm.files.removeFiles();
      return;
    }
    if (this.media.length + this.files.length + files.length > imageQuantityUploadLimit) {
      this.$toasted.show(this.mediaErrorList.ImageQuantityError, {
        className: 'toasted-error',
      });
    }
    const maxFilesToAdd = imageQuantityUploadLimit - (this.files.length + this.media.length);
    for (let i = 0; i < (files.length < maxFilesToAdd ? files.length : maxFilesToAdd); i += 1) {
      if (!imageTypes.includes(files[i].type) && !heicValidationRegex.test(files[i].name)) {
        errorMessages.add(this.mediaErrorList.ImageFormatError);
      } else if (files[i].size > imageSizeLimitInBytes) {
        errorMessages.add(this.mediaErrorList.ImageSizeError);
      } else {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.imageBlobs.push(e.target.result);
          this.files.push(files[i]);
        };
        reader.readAsDataURL(files[i]);
      }
    }
    this.mediaType = MediaTypes.Image;
    if (errorMessages.size) {
      this.mediaErrorMessages.push(...errorMessages);
      this.mediaErrors = true;
      target.value = '';
      vxm.files.removeFiles();
    }
  }

  removeNewImage(index: number): void {
    if (index < 0 && index >= this.imageBlobs.length) {
      return;
    }
    this.imageBlobs.splice(index, 1);
    this.files.splice(index, 1);
    if (!this.imageThumbnails.length && !this.imageBlobs.length) {
      this.mediaType = '';
    }
  }
  removeOldImage(index: number): void {
    if (index < 0 && index >= this.imageThumbnails.length) {
      return;
    }
    this.imageThumbnails.splice(index, 1);
    this.media.splice(index, 1);
    if (!this.imageThumbnails.length && !this.imageBlobs.length) {
      this.mediaType = '';
    }
  }

  onVideoPicked(event: Event, passedFile?: File) {
    let file: File;
    let target = {} as HTMLInputElement;
    if (passedFile) {
      file = passedFile;
      vxm.files.removeFile();
    } else {
      target = event.target as HTMLInputElement;
      file = (target.files as FileList)[0];
    }
    const errorMessages = new Set<string>();
    if (!file) {
      target.value = '';
      vxm.files.removeFile();
      return;
    }
    if (!videoTypes.includes(file.type)) {
      errorMessages.add(this.mediaErrorList.VideoFormatError);
    } else {
      if (videoTypesToTrim.includes(file.type) && file.size > videoSizeLimitBytes) {
        errorMessages.add(this.mediaErrorList.VideoSizeError);
      } else if (videoTypesUntrimmable.includes(file.type) && file.size > untrimmableVideoSizeLimitBytes) {
        errorMessages.add(this.mediaErrorList.UntrimmableVideoSizeError);
      }
    }
    if (errorMessages.size) {
      this.mediaErrorMessages.push(...errorMessages);
      this.mediaErrors = true;
      target.value = '';
      vxm.files.removeFile();
      return;
    }
    this.setVideoToTrim(file);
    target.value = '';
  }

  addVideo(event: Event, passedFile?: File, trimParams?: {startTime: number; duration: number}) {
    let file: File;
    let target = {} as HTMLInputElement;
    this.files = [];
    this.media = [];
    this.playerError = false;
    if (passedFile) {
      file = passedFile;
    } else if (trimParams && this.videoToTrimFile.size) {
      file = this.videoToTrimFile;
    } else {
      target = event.target as HTMLInputElement;
      file = (target.files as FileList)[0];
    }
    this.mediaType = MediaTypes.Video;
    this.files.push(file);
    this.onTrimCancelled();
  }

  setVideoToTrim(file: File) {
    this.videoToTrimFile = file;
    this.videoToTrimUrl = URL.createObjectURL(file);
    this.isTrimming = true;
  }

  removeVideo(): void {
    this.videoPreview = '';
    this.videoPreviewThumbnail = '';
    this.files = [];
    this.media = [];
    this.mediaType = '';
    this.playerError = false;
  }

  onTrimCancelled(): void {
    this.isTrimming = false;
    this.videoToTrimUrl = '';
    this.videoToTrimFile = {} as File;
  }

  onTrimConfirmed(params: {startTime: number; duration: number}): void {
    this.videoPreviewLimit.start = params.startTime;
    this.videoPreviewLimit.end = params.startTime + params.duration;
    this.addVideo(new Event('event'), undefined, params);
  }

  onResize(): void {
    let el = document.getElementById('hashtags-textarea') as HTMLInputElement;
    if (el) {
      el.style.height = '';
      el.style.height = `${el.scrollHeight}px`;
    }
    el = document.getElementById('message-textarea') as HTMLInputElement;
    if (el) {
      el.style.height = '';
      el.style.height = `${el.scrollHeight}px`;
    }
  }

  onSubmit(): void {
    if (this.cannotSubmit) {
      return;
    }
    vxm.post.createUpdatePost(this.getParams());
    this.$toasted.show(
      this.files.length ? 'The post will be created after uploading media files' : 'Post is successfully created.',
      {
        className: 'toasted-info',
      },
    );
    if (this.inModal) {
      this.$emit('postCreated');
    } else {
      this.$router.push({name: 'home'});
    }
  }

  getParams(): {} {
    const payload = [];
    payload.push({isEdit: this.isEditing});
    if (this.files.length) {
      payload.push({files: this.files});
      payload.push({blobs: this.imageBlobs});
    }
    if (this.videoPreviewLimit.end) {
      payload.push({startTime: this.videoPreviewLimit.start});
      payload.push({duration: this.videoPreviewLimit.end - this.videoPreviewLimit.start});
    }
    if (this.isEditing) {
      payload.push({
        params: {
          text: this.message,
          hashtags: Array.from(this.hashtags),
          mentions: this.mentionsArray.map((item) => item._id),
          media: this.media,
        },
      });
    } else {
      const obj: {text?: string; hashtags?: string[]; mentions?: string[]; media: string[]} = {media: []};
      if (this.message) {
        obj.text = this.message;
      }
      if (this.hashtags.size) {
        obj.hashtags = Array.from(this.hashtags);
      }
      if (this.mentionsArray.length) {
        obj.mentions = this.mentionsArray.map((item) => item._id);
      }
      payload.push({params: obj});
    }
    if (this.isEditing && this.post !== null) {
      payload.push({id: this.post._id});
    }
    return Object.assign({}, ...payload);
  }

  onMessageInputTyping(): void {
    if (!this.editingMessage || this.message.length < 2) {
      this.onMessageAutosuggestBlur();
      return;
    }
    const el = document.getElementById('message-textarea') as HTMLInputElement;
    const messageBeforeCursor = this.message.slice(0, el.selectionStart as number);
    const lastSpace = messageBeforeCursor.lastIndexOf(' ');
    const lastHash = messageBeforeCursor.lastIndexOf('#');
    const lastAt = messageBeforeCursor.lastIndexOf('@');
    let lastOccurrence: number;
    if (lastHash > lastAt) {
      lastOccurrence = lastHash;
      this.messageSuggestionType = SuggestionTypes.Hashtags;
    } else {
      lastOccurrence = lastAt;
      this.messageSuggestionType = SuggestionTypes.Mentions;
    }
    if (lastOccurrence !== -1 && lastOccurrence > lastSpace) {
      const suggestionQuery = messageBeforeCursor.slice(lastOccurrence + 1);
      if (!/\s+/.test(suggestionQuery)) {
        this.messageSuggestionQuery = suggestionQuery;
      }
    } else {
      this.onMessageAutosuggestBlur();
    }
  }

  onHashtagInputHashtagTyping(): void {
    if (!this.hashtagInput.length) {
      this.onHashtagsAutosuggestBlur();
      return;
    }
    const el = document.getElementById('hashtags-textarea') as HTMLInputElement;
    const messageBeforeCursor = this.hashtagInput.slice(0, el.selectionStart as number);
    const lastSpace = messageBeforeCursor.lastIndexOf(' ');
    const lastNewLine = messageBeforeCursor.lastIndexOf('\n');
    const lastOccurrence = lastSpace > lastNewLine ? lastSpace : lastNewLine;
    const lastWord = lastOccurrence !== -1 ? messageBeforeCursor.slice(lastOccurrence + 1) : messageBeforeCursor;
    if (/\s+/.test(lastWord)) {
      return;
    }
    this.hashtagOnHashtagsSuggestionQuery = lastWord.charAt(0) === '#' ? lastWord.slice(1) : lastWord;
  }

  async setHashtagBySuggestion(input: string, suggestion: {name: string}) {
    if (!suggestion || !suggestion.name) {
      return;
    }
    const el = document.getElementById(input) as HTMLInputElement;
    const cursorPosition = el.selectionStart as number;
    let newCursorPosition = 0;
    if (input === 'message-textarea') {
      const messageBeforeCursor = this.message.slice(0, cursorPosition);
      const lastHash = messageBeforeCursor.lastIndexOf('#');
      const unchanged = messageBeforeCursor.slice(0, lastHash);
      const newMessageBeforeCursor = `${unchanged}${suggestion.name}`;
      newCursorPosition = cursorPosition + (newMessageBeforeCursor.length - messageBeforeCursor.length);
      this.message = this.message.replace(messageBeforeCursor, newMessageBeforeCursor);
      if (this.setHashtagsFromMessageInput()) {
        this.styleMessageInput();
      }
      this.editingMessage = true;
      this.onMessageAutosuggestBlur();
      this.messageSuggestionQuery = '';
      this.pickFirstSuggestionOnMessage = false;
    } else if (input === 'hashtags-textarea') {
      const messageBeforeCursor = this.hashtagInput.slice(0, cursorPosition);
      const lastSpace = messageBeforeCursor.lastIndexOf(' ');
      const lastWord = lastSpace !== -1 ? messageBeforeCursor.slice(lastSpace + 1) : messageBeforeCursor;
      const newMessageBeforeCursor = messageBeforeCursor.replace(lastWord, suggestion.name);
      newCursorPosition = cursorPosition + (newMessageBeforeCursor.length - messageBeforeCursor.length);
      this.hashtagInput = this.hashtagInput.replace(messageBeforeCursor, newMessageBeforeCursor);
      this.hashtagsEditing = true;
      this.showSuggestionsOnHashtagInput = false;
      this.hashtagOnHashtagsSuggestionQuery = '';
      this.pickFirstSuggestionOnHashtags = false;
      this.setHashtagsFromHashtagsInput();
    }
    await this.$nextTick();
    el.focus();
    el.setSelectionRange(newCursorPosition, newCursorPosition);
  }

  onMessageAutosuggestBlur(): void {
    this.showSuggestionsOnMessageInput = false;
    const modal = document.getElementById('on-message');
    if (modal) {
      modal.scrollTop = 0;
    }
  }

  onHashtagsAutosuggestBlur(): void {
    this.showSuggestionsOnHashtagInput = false;
    const modal = document.getElementById('on-hashtags');
    if (modal) {
      modal.scrollTop = 0;
    }
  }

  onTextareaEscape(input: string): void {
    if (input === 'message-textarea') {
      if (this.showSuggestionsOnMessageInput) {
        this.onMessageAutosuggestBlur();
      } else {
        this.onMessageInputBlur();
      }
    } else if (input === 'hashtags-textarea') {
      if (this.showSuggestionsOnHashtagInput) {
        this.onHashtagsAutosuggestBlur();
      }
    }
  }

  onArrowNavigation(e: Event, changeBy: number): void {
    const el = e.target as HTMLElement;
    let suggestions = {} as HTMLCollectionOf<HTMLElement>;
    let input = {} as HTMLInputElement;
    if (el.id === 'message-textarea' || (el.parentNode as HTMLElement).id === 'on-message') {
      const hashtagsAutosuggest = document.getElementById('on-message') as HTMLElement;
      if (!hashtagsAutosuggest) {
        return;
      }
      input = document.getElementById('message-textarea') as HTMLInputElement;
      suggestions = hashtagsAutosuggest.getElementsByClassName('autosuggest-suggestion') as HTMLCollectionOf<
        HTMLElement
      >;
    } else if (el.id === 'hashtags-textarea' || (el.parentNode as HTMLElement).id === 'on-hashtags') {
      const hashtagsAutosuggest = document.getElementById('on-hashtags') as HTMLElement;
      if (!hashtagsAutosuggest) {
        return;
      }
      input = document.getElementById('hashtags-textarea') as HTMLInputElement;
      suggestions = hashtagsAutosuggest.getElementsByClassName('autosuggest-suggestion') as HTMLCollectionOf<
        HTMLElement
      >;
    }
    if (!document.activeElement || !input || !suggestions.length) {
      return;
    }
    if (input.id === 'message-textarea') {
      this.changeFocusedElementOnMessage(changeBy, suggestions);
    } else if (input.id === 'hashtags-textarea') {
      this.changeFocusedElementOnHashtags(changeBy, suggestions);
    }
  }

  changeFocusedElementOnMessage(changeBy: number, suggestions: HTMLCollectionOf<HTMLElement>) {
    const input = document.getElementById('message-textarea') as HTMLInputElement;
    if (changeBy === 1) {
      if (document.activeElement === input) {
        if (this.showSuggestionsOnMessageInput) {
          this.focusedSuggestionOnMessages = 0;
          suggestions[this.focusedSuggestionOnMessages].focus();
        } else {
          this.showSuggestionsOnMessageInput = true;
        }
      } else if (this.focusedSuggestionOnMessages === suggestions.length - 1) {
        input.focus();
      } else if (document.activeElement && document.activeElement.classList.contains('autosuggest-suggestion')) {
        suggestions[(this.focusedSuggestionOnMessages += 1)].focus();
      }
      return;
    }
    if (changeBy === -1) {
      if (document.activeElement === input) {
        if (this.showSuggestionsOnMessageInput) {
          this.focusedSuggestionOnMessages = suggestions.length - 1;
          suggestions[this.focusedSuggestionOnMessages].focus();
        } else {
          this.showSuggestionsOnMessageInput = true;
        }
      } else if (this.focusedSuggestionOnMessages === 0) {
        input.focus();
        input.setSelectionRange(this.message.length, this.message.length);
      } else if (document.activeElement && document.activeElement.classList.contains('autosuggest-suggestion')) {
        suggestions[(this.focusedSuggestionOnMessages -= 1)].focus();
      }
    }
  }

  changeFocusedElementOnHashtags(changeBy: number, suggestions: HTMLCollectionOf<HTMLElement>) {
    const input = document.getElementById('hashtags-textarea') as HTMLInputElement;
    if (changeBy === 1) {
      if (document.activeElement === input) {
        if (this.showSuggestionsOnHashtagInput) {
          this.focusedSuggestionOnHashtags = 0;
          suggestions[this.focusedSuggestionOnHashtags].focus();
        } else {
          this.showSuggestionsOnHashtagInput = true;
        }
      } else if (this.focusedSuggestionOnHashtags === suggestions.length - 1) {
        input.focus();
      } else if (document.activeElement && document.activeElement.classList.contains('autosuggest-suggestion')) {
        suggestions[(this.focusedSuggestionOnHashtags += 1)].focus();
      }
      return;
    }
    if (changeBy === -1) {
      if (document.activeElement === input) {
        if (this.showSuggestionsOnHashtagInput) {
          this.focusedSuggestionOnHashtags = suggestions.length - 1;
          suggestions[this.focusedSuggestionOnHashtags].focus();
        } else {
          this.showSuggestionsOnHashtagInput = true;
        }
      } else if (this.focusedSuggestionOnHashtags === 0) {
        input.focus();
        input.setSelectionRange(this.hashtagInput.length, this.hashtagInput.length);
      } else if (document.activeElement && document.activeElement.classList.contains('autosuggest-suggestion')) {
        suggestions[(this.focusedSuggestionOnHashtags -= 1)].focus();
      }
    }
  }

  onHideAutosuggest(): void {
    if (this.showSuggestionsOnHashtagInput) {
      const input = document.getElementById('hashtags-textarea') as HTMLInputElement;
      if (input) {
        input.focus();
      }
      this.showSuggestionsOnHashtagInput = false;
    } else if (this.showSuggestionsOnMessageInput) {
      const input = document.getElementById('message-textarea') as HTMLInputElement;
      if (input) {
        input.focus();
      }
      this.showSuggestionsOnMessageInput = false;
    }
  }

  async setPostToEdit() {
    if (this.post?.text && this.post?.text.length) {
      this.message = this.post.text;
      await this.$nextTick();
      const el = document.getElementById('message-textarea') as HTMLInputElement;
      if (el) {
        el.style.height = '';
        el.style.height = `${el.scrollHeight}px`;
        el.blur();
        el.focus();
      }
    }
    if (this.post?.hashtags && this.post?.hashtags?.length) {
      this.hashtagsFromHashtagsInput = new Set<string>(this.post.hashtags);
    }
    if (this.post?.mentions && this.post?.mentions?.length) {
      this.post.mentions.forEach((mention) => {
        this.mentions.set(mention._id, mention);
      });
      this.mentionsArray = Array.from(this.mentions.values());
    }
    if (this.post?.media && this.post?.media.length) {
      if (this.post.media[0].type === MediaTypes.Image) {
        this.mediaType = MediaTypes.Image;
        for (let i = 0; i < this.post.media.length; i += 1) {
          this.imageThumbnails.push(this.post.media[i].link.large || this.post.media[i].link.origin);
          this.media.push(this.post.media[i]._id);
        }
      } else if (this.post.media[0].type === MediaTypes.Video) {
        this.mediaType = MediaTypes.Video;
        this.videoPreview = this.post.media[0].link.origin;
        this.media.push(this.post.media[0]._id);
      }
    }
  }
  onPlayerError() {
    this.playerError = true;
  }
  updatePost(): void {
    if (this.cannotSubmit || this.post === null || !this.isEditing) {
      return;
    }
    vxm.post.createUpdatePost(this.getParams());
    this.$toasted.show(
      this.files.length ? 'The post will be updated after uploading media files' : 'Post is successfully updated.',
      {
        className: 'toasted-info',
      },
    );
    this.$emit('postUpdated');
  }

  closeModal(): void {
    if (this.isEditing) {
      this.$emit('cancelPostEditing');
    }
  }

  onViewImages(index: number, isNew?: boolean): void {
    this.imageViewerIndex = isNew ? index + this.imageThumbnails.length : index;
  }

  setBackground(url: string): {} {
    return {
      background: `url(${url}) center/cover no-repeat`,
    };
  }

  async setMentionBySuggestion(suggestion: {username: string; _id: string}) {
    if (!Object.values(suggestion).length || !suggestion._id) {
      return;
    }
    const el = document.getElementById('message-textarea') as HTMLInputElement;
    const cursorPosition = el.selectionStart as number;
    const messageBeforeCursor = this.message.slice(0, cursorPosition);
    const lastAt = messageBeforeCursor.lastIndexOf('@');
    const unchanged = messageBeforeCursor.slice(0, lastAt);
    const newMessageBeforeCursor = `${unchanged}@${suggestion.username}`;
    const newCursorPosition = cursorPosition + (newMessageBeforeCursor.length - messageBeforeCursor.length);
    this.message = this.message.replace(messageBeforeCursor, newMessageBeforeCursor);
    if (this.setMentions(suggestion)) {
      this.styleMessageInput();
    }
    this.editingMessage = true;
    this.showSuggestionsOnMessageInput = false;
    this.messageSuggestionQuery = '';
    this.pickFirstSuggestionOnMessage = false;
    await this.$nextTick();
    el.focus();
    el.setSelectionRange(newCursorPosition, newCursorPosition);
  }

  setMentions(suggestion: {username: string; _id: string}): boolean {
    this.messageError = false;
    if (!this.mentions.has(suggestion._id) && this.mentions.size >= mentionQuantityUploadLimit) {
      this.$toasted.show(this.mentionErrorList.MentionQuantityError, {
        className: 'toasted-error',
      });
      return false;
    }
    this.mentions.set(suggestion._id, suggestion);
    this.mentionsArray = Array.from(this.mentions.values());
    return true;
  }

  actualizeMentions(): void {
    const mentions = this.message
      .trim()
      .split(/\s+/)
      .filter((word) => mentionValidationRegex.test(word));
    this.mentions.forEach((value, key) => {
      if (mentions.indexOf(`@${value.username}`) === -1) {
        this.mentions.delete(key);
      }
    });
  }

  onMessageEnterPressed(event: KeyboardEvent): void {
    if (this.showSuggestionsOnMessageInput) {
      event.preventDefault();
      this.pickFirstSuggestionOnMessage = true;
    }
  }

  onHashtagsEntered(event: KeyboardEvent): void {
    if (this.showSuggestionsOnHashtagInput) {
      this.pickFirstSuggestionOnHashtags = true;
    } else {
      this.setHashtagsFromHashtagsInput();
    }
    if (
      !(
        this.hashtagError &&
        this.hashtagErrorMessage === this.hashtagErrorList.HashtagValidationError &&
        event.key === ' '
      )
    ) {
      event.preventDefault();
    }
  }

  setHashtagsFromHashtagsInput(): void {
    this.disableSuggestionsOnHashtags = true;
    if (!this.hashtagInput) {
      return;
    }
    this.hashtagError = false;
    if (!hasRestrictedWords(this.hashtagInput)) {
      this.hashtagErrorMessage = this.hashtagErrorList.RestrictedWordsError + matchRestrictedWord(this.hashtagInput);
      this.hashtagError = true;
      return;
    }
    const hashtags = this.hashtagInput.trim().split(/\s+/);
    if (hashtags.length > hashtagQuantityUploadLimit) {
      this.hashtagErrorMessage = this.hashtagErrorList.HashtagQuantityError;
      this.hashtagError = true;
      return;
    }
    for (let i = 0; i < hashtags.length; i += 1) {
      if (hashtags[i].charAt(0) !== '#') {
        hashtags[i] = `#${hashtags[i]}`;
      }
    }
    if (hashtags.some((hashtag) => hashtag.length > maxHashtagLength)) {
      this.hashtagErrorMessage = this.hashtagErrorList.HashtagLengthError;
      this.hashtagError = true;
    }
    if (hashtags.some((hashtag) => !hashtagValidationRegex.test(hashtag))) {
      this.hashtagErrorMessage = this.hashtagErrorList.HashtagValidationError;
      this.hashtagError = true;
    } else {
      hashtags.forEach((hashtag) => {
        this.hashtagsFromHashtagsInput.add(hashtag);
        this.hashtagChanges += 1;
      });
      this.hashtagInput = '';
    }
  }

  onHaveSuggestionsOnHashtags(haveSuggestions: boolean): void {
    this.showSuggestionsOnHashtagInput = haveSuggestions;
  }

  removeHashtag(hashtag: string): void {
    if (this.hashtagsFromHashtagsInput.has(hashtag)) {
      this.hashtagsFromHashtagsInput.delete(hashtag);
      this.hashtagChanges += 1;
    }
    if (this.hashtagsFromMessageInput.has(hashtag)) {
      this.hashtagsFromMessageInput.delete(hashtag);
      this.hashtagChanges += 1;
      this.message = this.message.replace(new RegExp(`${hashtag} |${hashtag}`, 'g'), '');
      this.editingMessage = true;
      this.onMessageInputBlur();
    }
  }

  removeMention(mention: {_id: string; username: string}): void {
    this.mentions.delete(mention._id);
    this.mentionsArray = Array.from(this.mentions.values());
    this.message = this.message.replace(new RegExp(`@${mention.username} |@${mention.username}`, 'g'), '');
    this.editingMessage = true;
    this.onMessageInputBlur();
  }

  closeProfileActivityPreferencesModal(): void {
    if (vxm.user.postPreferencesUnset) {
      this.$router.push('/');
      return;
    }
    this.showProfileActivityPreferencesModal = false;
  }

  onUserUpdated(): void {
    if (vxm.user.postPreferencesUnset) {
      this.$router.push('/');
      return;
    }
    this.$router.go(0);
  }
}
