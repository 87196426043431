





import {Vue, Component} from 'vue-property-decorator';

@Component({name: 'LoaderBottom'})
export default class LoaderBottom extends Vue {}
