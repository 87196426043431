

















import {Component, Vue} from 'vue-property-decorator';
import {vxm} from '@/store';
import LoaderBottom from '@/components/LoaderBottom.vue';
import NoContent from '@/components/NoContent.vue';
import {UserDataInterface} from '@/types/UserDataInterface';

@Component({
  components: {LoaderBottom, NoContent},
})
export default class RecommendedBlogs extends Vue {
  loading = false;
  recommendedBlogs = [] as UserDataInterface[];

  get noContentMessage(): string {
    return 'No new recommendations found...\nTry coming later!';
  }

  created() {
    this.getRecommendedBlogs();
  }

  getRecommendedBlogs(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    vxm.user
      .getRecommendedBlogs()
      .then((res: {data: {data: UserDataInterface[]}}) => {
        this.recommendedBlogs = res.data.data;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  userAvatar(blog: UserDataInterface): string {
    return blog.mediaId && blog.mediaId.link
      ? blog.mediaId.link.medium || blog.mediaId.link.origin
      : require('@/assets/icons/avatar-default.svg');
  }
  formatCount(count: number, property: string): string {
    if (count === 0) {
      return `0 ${property}`;
    }
    const digits = count.toString().length;
    switch (true) {
      case digits < 4:
        return `${count} ${property}`;
      case digits < 7:
        return `${Math.round((count / 1000 + Number.EPSILON) * 100) / 100}K ${property}`;
      case digits < 10:
        return `${Math.round((count / 1000000 + Number.EPSILON) * 100) / 100}M ${property}`;
      case digits < 13:
        return `${Math.round((count / 1000000000 + Number.EPSILON) * 100) / 100}B ${property}`;
      case digits < 16:
        return `${Math.round((count / 1000000000000 + Number.EPSILON) * 100) / 100}T ${property}`;
      default:
        return '';
    }
  }
}
