







import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({name: 'UploadProgressBar'})
export default class UploadProgressBar extends Vue {
  @Prop({required: true}) readonly progress!: number;
  @Prop() readonly message!: string;

  get stateIndicatorWidth(): {} {
    return {
      '--width': `${this.progress}%`,
    };
  }
}
