import api from './api';
import {GetMessagesParamsInterface} from '@/types/GetMessagesParamsInterface';

export default {
  getRooms(params: {page: number; limit: number}) {
    return api.get('/rooms', {params});
  },
  getRoom(id: string) {
    return api.get(`/rooms/${id}`);
  },
  createRoom(data: {userId: string}) {
    return api.post('/rooms', data);
  },
  deleteRoom(id: string) {
    return api.delete(`/rooms/${id}`);
  },
  getMessages(id: string, params: GetMessagesParamsInterface) {
    return api.get(`/rooms/${id}/messages`, {params});
  },
  createMessage(id: string, data: {message: string; medias: string[]}) {
    return api.post(`/rooms/${id}/messages`, data);
  },
  deleteMessage(roomId: string, messageId: string) {
    return api.delete(`/rooms/${roomId}/messages/${messageId}`);
  },
};
