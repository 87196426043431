import {DirectiveOptions} from 'vue';

const clickOutside: DirectiveOptions = {
  bind: function(el: any, binding: any, vnode: any) {
    el.onMouseDown = function(event: MouseEvent) {
      el.setAttribute('on-down-is-outside', `${el !== event.target}`);
    };
    el.onMouseUp = function(event: MouseEvent) {
      const onDownIsOutside = el.getAttribute('on-down-is-outside') === 'true';
      if (onDownIsOutside && el !== event.target) {
        vnode.context[binding.expression](event);
      }
    };
    setTimeout(() => {
      document.body.addEventListener('mousedown', el.onMouseDown);
      document.body.addEventListener('mouseup', el.onMouseUp);
    });
  },
  unbind: function(el: any) {
    document.body.removeEventListener('mousedown', el.onMouseDown);
    document.body.removeEventListener('mouseup', el.onMouseUp);
  },
};

export default clickOutside;
