











import {Component, Vue} from 'vue-property-decorator';
import {vxm} from '@/store';
import ProfileActivityPreferences from '@/components/ProfileActivityPreferences.vue';
import Loader from '@/components/Loader.vue';
import clickInside from '@/utils/clickInside';

@Component({
  components: {ProfileActivityPreferences, Loader},
  directives: {clickInside},
})
export default class ProfileActivityPreferencesModal extends Vue {
  loading = true;
  wantToPostTemplate = {} as any;
  wantToPost = {} as {[key: string]: string};
  otherWantToPost = undefined as undefined | string;

  get invalidPreferences(): boolean {
    return (
      (!this.wantToPost.withoutCloth && !this.wantToPost.inCloth) ||
      (this.wantToPost.inCloth === 'Other' && (!this.otherWantToPost || this.otherWantToPost.length > 25))
    );
  }

  created() {
    vxm.user
      .getEnums()
      .then((res: {data: {data: {wantToPost: any}}}) => {
        this.wantToPostTemplate = res.data.data.wantToPost;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onPreferencesUpdated(payload: {wantToPost: {[key: string]: string}; otherWantToPost?: string}): void {
    this.wantToPost = payload.wantToPost;
    this.otherWantToPost = payload.otherWantToPost;
  }
  closeModal(): void {
    this.$emit('closeProfileActivityPreferencesModal');
  }
  onSubmit(): void {
    if (this.loading || this.invalidPreferences) {
      return;
    }
    this.loading = true;
    const params = {
      wantToPost: this.wantToPost,
      ...(this.otherWantToPost && {otherWantToPost: this.otherWantToPost}),
    };
    vxm.user
      .updateUser(params)
      .then(() => {
        this.$toasted.show('Profile is successfully updated.', {
          className: 'toasted-info',
        });
        this.$emit('userUpdated');
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
