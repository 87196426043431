import {action, Module, VuexModule} from 'vuex-class-component';
import WebsiteInterface from '@/types/WebsiteInterface';
import WebsitesApi from '@/service/websitesApi';

@Module({namespacedPath: 'website/'})
export class WebsiteStore extends VuexModule {
  @action
  async addWebsite(payload: WebsiteInterface) {
    return WebsitesApi.newSite(payload);
  }
  @action
  async updateWebsite(payload: {website: {title: string; domain: string; cover: string}; id: string}) {
    return WebsitesApi.updateWebsite(payload);
  }
  @action
  async deleteWebsite(id: string) {
    return WebsitesApi.deleteWebsite(id);
  }
}
