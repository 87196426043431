import {action, Module, VuexModule} from 'vuex-class-component';
import FeedApi from '@/service/feedApi';

@Module({namespacedPath: 'feed/'})
export class FeedStore extends VuexModule {
  @action
  async getFeed(params: {page: number; limit: number}) {
    return FeedApi.getFeed(params);
  }
  @action
  async getRandomFeed(params: {limit: number}) {
    return FeedApi.getRandomFeed(params);
  }
}
