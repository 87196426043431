import api from './api';
import GetWebsitePayloadInterface from '@/types/GetWebsitePayloadInterface';
import WebsiteInterface from '@/types/WebsiteInterface';

const WebsitesApi = {
  getWebsites(payload: GetWebsitePayloadInterface) {
    return api.get('/websites', {
      params: payload,
    });
  },
  newSite(payload: WebsiteInterface) {
    return api.post('/websites', payload);
  },
  updateWebsite(payload: {website: {title: string; domain: string; cover: string}; id: string}) {
    return api.put(`/websites/${payload.id}`, payload.website);
  },
  deleteWebsite(id: string) {
    return api.delete(`/websites/${id}`);
  },
};

export default WebsitesApi;
