import {action, getter, Module, mutation, VuexModule} from 'vuex-class-component';
import MediaApi from '@/service/mediaApi';
import PostsApi from '@/service/postsApi';
import HashtagsApi from '@/service/hashtagsApi';
import LikesApi from '@/service/likesApi';
import FavoritesApi from '@/service/favoritesApi';
import UsersApi from '@/service/usersApi';
import {ApiMediaTypes} from '@/constants/apiMediaTypes';

@Module({namespacedPath: 'post/'})
export class PostStore extends VuexModule {
  @getter showNewPostModal = false;
  @getter uploadingState: {id: string; progress: number; blobs: string[]; media: File[]}[] = [];
  @getter profileNeedToUpdate = false;

  @mutation setShowNewPostModal(data: boolean) {
    this.showNewPostModal = data;
  }

  @mutation setProfileNeedToUpdate(data: boolean) {
    this.profileNeedToUpdate = data;
  }

  @mutation pushToUploadingState(data: {id: string; progress: number; blobs: string[]; media: File[]}) {
    this.uploadingState.push(data);
  }

  @mutation updateUploadingState(data: {id: string; progress: number}) {
    const item = this.uploadingState.find((x) => x.id === data.id);
    if (item) {
      item.progress = data.progress;
    }
  }

  @mutation removeFromUploadingState(id: string) {
    this.uploadingState.splice(
      this.uploadingState.findIndex((x) => x.id === id),
      1,
    );
  }

  @action
  async addMedia(payload: {
    files: File[];
    contentType: ApiMediaTypes;
    duration?: number;
    startTime?: number;
    onUploadProgressEvent?: (progressEvent: ProgressEvent) => void;
  }) {
    return MediaApi.newMedia(payload);
  }

  @action
  async createUpdatePost(payload: {
    id: string;
    params: {text: string; media: string[]};
    files?: File[];
    blobs?: string[];
    startTime?: number;
    duration?: number;
    isEdit?: boolean;
  }) {
    if (payload.files && payload.blobs) {
      const id =
        '_' +
        Math.random()
          .toString(36)
          .substr(2, 9);
      this.pushToUploadingState({id: id, progress: 0, blobs: payload.blobs, media: payload.files});
      const onUploadProgressEvent = (progressEvent: ProgressEvent) => {
        this.updateUploadingState({id: id, progress: Math.round((progressEvent.loaded / progressEvent.total) * 100)});
      };
      const type = payload.files[0].type.split('/')[0] as ApiMediaTypes;
      return this.addMedia({
        files: payload.files,
        contentType: type,
        startTime: payload.startTime,
        duration: payload.duration,
        onUploadProgressEvent: onUploadProgressEvent,
      })
        .then((res) => {
          res.data.data.forEach((item: {_id: string}) => {
            payload.params.media.push(item._id);
          });
          this.removeFromUploadingState(id);
          const apiCall = payload.isEdit
            ? PostsApi.updatePost(payload.id, payload.params)
            : PostsApi.newPost(payload.params);
          return apiCall.then(() => {
            this.setProfileNeedToUpdate(true);
          });
        })
        .catch(() => {
          this.removeFromUploadingState(id);
        });
    } else {
      const apiCall = payload.isEdit
        ? PostsApi.updatePost(payload.id, payload.params)
        : PostsApi.newPost(payload.params);
      return apiCall.then(() => {
        this.setProfileNeedToUpdate(true);
      });
    }
  }

  @action
  async deletePost(id: string) {
    return PostsApi.deletePost(id);
  }

  @action
  async getPost(id: string) {
    return PostsApi.getPost(id);
  }

  @action
  async getHashtags(query: string) {
    return HashtagsApi.getHashtags(query);
  }

  @action
  async like(payload: {objectId: string; objectType: string}) {
    return LikesApi.like(payload);
  }

  @action
  async favor(payload: {postId: string}) {
    return FavoritesApi.favor(payload);
  }

  @action
  async disfavor(payload: {postId: string}) {
    return FavoritesApi.disfavor(payload);
  }

  @action
  async getFavs() {
    return FavoritesApi.getFavs();
  }

  @action
  async getComments(postId: string) {
    return PostsApi.getComments(postId);
  }

  @action
  async addComment(payload: {postId: string; text: string}) {
    return PostsApi.addComment(payload);
  }

  @action
  async editComment(payload: {id: string; text: string}) {
    return PostsApi.editComment(payload);
  }

  @action
  async deleteComment(id: string) {
    return PostsApi.deleteComment(id);
  }

  @action
  async likeComment(payload: {objectId: string; objectType: string}) {
    return LikesApi.like(payload);
  }

  @action getSubcomments(payload: {postId: string; parentId: string}) {
    return PostsApi.getSubcomments(payload);
  }

  @action addSubcomment(payload: {postId: string; parentId: string; mainId: string; text: string}) {
    return PostsApi.addSubcomment(payload);
  }

  @action
  async editSubcomment(payload: {id: string; text: string}) {
    return PostsApi.editSubcomment(payload);
  }

  @action
  async deleteSubcomment(id: string) {
    return PostsApi.deleteSubcomment(id);
  }

  @action
  async repost(id: string) {
    return PostsApi.repost(id);
  }

  @action
  async reportPost(payload: {postId: string; type: string}) {
    return PostsApi.report(payload);
  }

  @action
  async getMentionSuggestions(query: string) {
    return UsersApi.getMentionSuggestions(query);
  }
}
