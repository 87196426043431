import Vue from 'vue';
import router from '@/router';
import {store, vxm} from '@/store';
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';

const baseURL = process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : ``;

const ax = axios.create({
  baseURL,
});
ax.interceptors.request.use((request: AxiosRequestConfig) => {
  if (request.headers['Authorization'] && request.headers['Authorization'].split(' ')[1] === vxm.user.refreshToken) {
    return request;
  }
  if (vxm.user.accessToken) {
    request.headers['Authorization'] = `Bearer ${vxm.user.accessToken}`;
  }
  return request;
});
ax.interceptors.response.use(
  (res: AxiosResponse) => {
    return res;
  },
  (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      if (vxm.user.refreshToken) {
        originalRequest._retry = true;
        return vxm.user
          .reLogin(vxm.user.refreshToken)
          .then(() => {
            return ax(originalRequest);
          })
          .catch(() => {
            store.dispatch('resetStore').then(() => {
              router.push('/login');
            });
          });
      } else {
        store.dispatch('resetStore').then(() => {
          router.push('/login');
        });
      }
    } else if (error.response.data && error.response.data.message) {
      Vue.toasted.show(error.response.data.message, {
        className: 'toasted-error',
      });
    }
    return Promise.reject(error);
  },
);

export default ax;
