import {vxm} from '@/store';

const wordsRegExp = function(vocabulary: string[]) {
  return new RegExp('\\b(' + vocabulary.join('|').toLowerCase() + ')\\b');
};

const hasRestrictedWords = (value: string) => {
  return vxm.user.vocabulary && vxm.user.vocabulary.length
    ? !wordsRegExp(vxm.user.vocabulary).test(value.toLowerCase())
    : true;
};

const matchRestrictedWord = (value: string) => {
  const result = value.toLowerCase().match(wordsRegExp(vxm.user.vocabulary));
  return result ? result[0] : '';
};

export {hasRestrictedWords, matchRestrictedWord, wordsRegExp};
