import api from './api';

export default {
  getUser(id: string) {
    return api.get(`/users/${id}`);
  },
  getUsers() {
    return api.get('/users');
  },
  updateUser(payload: {}) {
    return api.put('/users/update', payload);
  },
  getPosts(payload: {id: string; page: number; limit: number}) {
    return api.get(`/users/${payload.id}/posts`, {
      params: {
        page: payload.page,
        limit: payload.limit,
      },
    });
  },
  follow(payload: {}) {
    return api.post('/followers', payload);
  },
  getFollowers(payload: {id: string; page: number; limit: number}) {
    return api.get(`/followers/${payload.id}`, {
      params: {
        page: payload.page,
        limit: payload.limit,
      },
    });
  },
  getFollowing(payload: {id: string; page: number; limit: number}) {
    return api.get(`/followers/${payload.id}?following=true`, {
      params: {
        page: payload.page,
        limit: payload.limit,
      },
    });
  },
  answerFollowRequest(payload: {followerId: string; accepted: boolean}) {
    return api.post('/followers/accept', payload);
  },
  getMentionSuggestions(query: string) {
    return api.get('/users', {
      params: {
        username: query,
      },
    });
  },
  getRecommendedBlogs() {
    return api.get('users/recommended');
  },
  getRestrictedWords(token: string) {
    return api.get('/words', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getEnums(accessToken: string | undefined) {
    return api.get('users/enums', {
      ...(accessToken && {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    });
  },
  reportUser(params: {userId: string; type: string; reason?: string}) {
    return api.post('reports/users', params);
  },
  getBlockedUsers() {
    return api.get('users-meta/block');
  },
  blockUser(userId: string) {
    return api.post('users-meta/block/' + userId);
  },
  unblockUser(userId: string) {
    return api.delete('users-meta/block/' + userId);
  },
  getMutedUsers() {
    return api.get('users-meta/mute');
  },
  muteUser(userId: string) {
    return api.post('users-meta/mute/' + userId);
  },
  unmuteUser(userId: string) {
    return api.delete('users-meta/mute/' + userId);
  },
};
