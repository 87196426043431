import {action, getter, Module, mutation, VuexModule} from 'vuex-class-component';
import InvitationsApi from '@/service/invitationsApi';
import OauthApi from '@/service/oauthApi';
import {InvitationsInterface} from '@/types/InvitationsInterface';

@Module({namespacedPath: 'invitations/'})
export class InvitationStore extends VuexModule {
  @getter invitations = {} as InvitationsInterface;

  @mutation setInvitationData(payload: InvitationsInterface) {
    this.invitations = payload;
  }

  @action
  async getInvitationsAvailable() {
    return InvitationsApi.getInvitationsAvailable().then((res: {data: InvitationsInterface}) => {
      this.setInvitationData(res.data);
      return res.data.available;
    });
  }

  @action
  async signUpVerifyEmail(data: {email: string; inviteToken: string}) {
    return OauthApi.signUpVerifyEmail(data);
  }

  @action
  checkInvitation(inviteToken: string) {
    return InvitationsApi.checkInvitation(inviteToken);
  }

  @action
  getNewInvitation() {
    return InvitationsApi.getNewInvitation().then((res: {data: InvitationsInterface}) => {
      this.setInvitationData(res.data);
      return res;
    });
  }
}
