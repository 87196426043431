
















import {Component, Vue} from 'vue-property-decorator';
import {vxm} from '@/store';
import VueSocketIO from 'vue-socket.io';
import SocketIO from 'socket.io-client';
import Header from '@/components/Header.vue';
import DesktopAsideNav from '@/components/DesktopAsideNav.vue';
import RecommendedBlogs from '@/components/RecommendedBlogs.vue';
import Loader from '@/components/Loader.vue';
import CreatePostFloatingButton from '@/components/CreatePostFloatingButton.vue';
import {Themes} from '@/constants/themes';
import {BrowserNames} from '@/constants/browserNames';
import {MessageInterface} from '@/types/MessageInterface';
import NewPost from '@/components/modals/NewPost.vue';
import UploadingProgress from '@/components/UploadingProgress.vue';

Vue.use(
  new VueSocketIO({
    connection: SocketIO(process.env.VUE_APP_SOCKET_URL, {
      query: {token: vxm.user.accessToken},
      transports: ['websocket', 'polling'],
      autoConnect: false,
    }),
  }),
);

@Component({
  components: {
    UploadingProgress,
    NewPost,
    Loader,
    RecommendedBlogs,
    DesktopAsideNav,
    Header,
    CreatePostFloatingButton,
  },
})
export default class App extends Vue {
  loading = true;

  get showProgress() {
    return !!vxm.post.uploadingState.length;
  }
  get showNewPostModal() {
    return vxm.post.showNewPostModal;
  }

  created() {
    this.checkBrowser();
    this.loading = false;
  }

  mounted() {
    document.body.setAttribute('theme', vxm.user.data.isDarkMode ? Themes.Dark : Themes.Light);

    this.sockets.subscribe('connect_error', () => {
      if (this.$socket.io.opts.transports[0] === 'websocket') {
        this.$socket.io.opts.transports = ['polling', 'websocket'];
        this.$socket.connect();
      }
    });

    if (this.UserToken) {
      this.$socket.connect();
      vxm.user.getRestrictedWords();
      vxm.user.getMutedUsers();
      vxm.user.getBlockedUsers();
    }

    this.sockets.subscribe('notifications:new', (res: {notifications: number; mentions: number}) => {
      vxm.user.setNotificationCounter(res.notifications);
      vxm.user.setMentionCounter(res.mentions);
    });
    this.$socket.emit('notifications:fetchCounts');

    this.sockets.subscribe('message', (res: {data: MessageInterface; event: string}) => {
      if (this.$route.path !== '/chat') {
        this.$toasted.show(`New message: ${res.data.message}`, {
          className: 'toasted-info',
          position: 'bottom-right',
          action: {
            text: 'View',
            onClick: (e, toastObject) => {
              this.$router.push(`/chat?chatId=${res.data.roomId}`);
              toastObject.goAway(0);
            },
          },
        });
      }
    });
  }

  destroyed() {
    this.sockets.unsubscribe('message');
    this.sockets.unsubscribe('notifications:new');
  }

  get UserToken() {
    return vxm.user.accessToken;
  }

  closeNewPostModal() {
    vxm.post.setShowNewPostModal(false);
  }

  checkBrowser() {
    if (!this.$store.state.browser) {
      if (navigator.userAgent.indexOf('Opera') !== -1 || navigator.userAgent.indexOf('OPR') !== -1) {
        this.$store.dispatch('setBrowser', BrowserNames.Opera);
      } else if (navigator.userAgent.indexOf('Edg') !== -1) {
        this.$store.dispatch('setBrowser', BrowserNames.Edge);
      } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
        this.$store.dispatch('setBrowser', BrowserNames.Chrome);
      } else if (navigator.userAgent.indexOf('Safari') !== -1) {
        this.$store.dispatch('setBrowser', BrowserNames.Safari);
      } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
        this.$store.dispatch('setBrowser', BrowserNames.Firefox);
      }
      if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        this.$store.dispatch('setMobile', true);
      }
    }
  }
}
