import api from './api';

const SearchApi = {
  doSearch(payload: {}) {
    const params: string[] = [];
    Object.entries(payload).forEach(([key, value]) => {
      params.push(`${key}=${value}&`);
    });
    return api.get(`/search?${params.join('')}`);
  },
  latestSearch(payload?: string[]) {
    return api.get('/users-meta/search-history', {params: {searchHistory: payload}});
  },
  deleteSearch(payload?: string[]) {
    return api.delete('/users-meta/search-history', {params: {searchHistory: payload}});
  },
};

export default SearchApi;
