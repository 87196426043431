














import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import debounce from '@/utils/debounce';
import {vxm} from '@/store';
import PeopleInterface from '@/types/PeopleInterface';

@Component({components: {}})
export default class Header extends Vue {
  @Prop({type: String, required: true}) readonly searchInput!: string;
  userList = [] as PeopleInterface[];
  debounceHandler = () => {
    /* Will be replaced */
  };
  @Watch('searchInput')
  onSearchInputChange() {
    this.debounceHandler();
  }
  @Watch('suggestionList', {deep: true})
  onSuggestionListChange() {
    const haveSuggestions = !!this.userList.length;
    this.$emit('haveSuggestions', haveSuggestions);
  }
  getAvatar(index: number) {
    return this.userList[index].avatar || require('@/assets/icons/avatar-default.svg');
  }
  created() {
    this.debounceHandler = debounce(this.getSuggestions, 200);
  }
  getSuggestions(): void {
    if (this.searchInput) {
      vxm.search.doSearch({search: this.searchInput}).then((res: any) => {
        const data = res.data.data;
        this.userList = data.users && data.users.length ? data.users : [];
      });
    }
  }
  passSuggestion(suggestion: {}): void {
    this.$emit('searchBySuggestion', suggestion);
  }
  addToSearch(id: string): void {
    vxm.search.saveLastSearch([id]);
  }
}
