import {UserStore} from '@/store/modules/user.ts';
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import {PostStore} from '@/store/modules/post';
import {WebsiteStore} from '@/store/modules/website';
import {SearchStore} from '@/store/modules/search';
import {FeedStore} from '@/store/modules/feed';
import {FilesStore} from '@/store/modules/files';
import createMutationsSharer from 'vuex-shared-mutations';
import {InvitationStore} from '@/store/modules/invitations';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    browser: '',
    isMobile: false,
  },
  modules: {
    user: UserStore.ExtractVuexModule(UserStore),
    post: PostStore.ExtractVuexModule(PostStore),
    website: WebsiteStore.ExtractVuexModule(WebsiteStore),
    search: SearchStore.ExtractVuexModule(SearchStore),
    feed: FeedStore.ExtractVuexModule(FeedStore),
    files: FilesStore.ExtractVuexModule(FilesStore),
    invitations: InvitationStore.ExtractVuexModule(InvitationStore),
  },
  mutations: {
    changeBrowser(state, browserName: string) {
      state.browser = browserName;
    },
    changeMobile(state, isMobile: boolean) {
      state.isMobile = isMobile;
    },
  },
  actions: {
    resetStore({commit}) {
      commit('user/reset');
    },
    setBrowser({commit}, browserName: string) {
      commit('changeBrowser', browserName);
    },
    setMobile({commit}, isMobile: boolean) {
      commit('changeMobile', isMobile);
    },
  },
  strict: process.env.NODE_ENV === 'development',
  plugins: [
    createPersistedState({
      paths: ['browser', 'isMobile', 'user', 'search'],
    }),
    createMutationsSharer({predicate: ['user/reset']}),
  ],
});

export const vxm = {
  user: UserStore.CreateProxy(store, UserStore),
  post: PostStore.CreateProxy(store, PostStore),
  website: WebsiteStore.CreateProxy(store, WebsiteStore),
  search: SearchStore.CreateProxy(store, SearchStore),
  feed: FeedStore.CreateProxy(store, FeedStore),
  files: FilesStore.CreateProxy(store, FilesStore),
  invitations: InvitationStore.CreateProxy(store, InvitationStore),
};

export default store;
