
















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {vxm} from '@/store';
import {SuggestionTypes} from '@/constants/suggestionTypes';
import HashtagAutosuggestionInterface from '@/types/HashtagAutosuggestionInterface';

@Component({name: 'AutosuggestModal'})
export default class AutosuggestModal extends Vue {
  @Prop({type: String, required: true}) readonly query!: string;
  @Prop({type: String, default: SuggestionTypes.Hashtags}) readonly type!: string;
  @Prop({type: Boolean, default: false}) readonly pickFirstSuggestion!: boolean;

  suggestionList = [] as (HashtagAutosuggestionInterface | {username: string; _id: string})[];
  SuggestionTypes = SuggestionTypes;

  @Watch('query')
  onQueryChange() {
    if (this.query) {
      this.getSuggestions();
    }
  }
  @Watch('suggestionList', {deep: true})
  onSuggestionsChange() {
    const haveSuggestions = !!this.suggestionList.length;
    this.$emit('haveSuggestions', haveSuggestions);
  }
  @Watch('pickFirstSuggestion')
  onPickFirstSuggestionChange() {
    if (this.pickFirstSuggestion && this.suggestionList.length) {
      this.passSuggestion(this.suggestionList[0]);
    }
  }

  getSuggestions(): void {
    if (!this.query) {
      return;
    }
    if (this.type === SuggestionTypes.Hashtags) {
      vxm.post.getHashtags(this.query).then((res: {data: {data: HashtagAutosuggestionInterface[]}}) => {
        const data = res.data.data;
        this.suggestionList = data && data.length ? data : [];
      });
      return;
    }
    if (this.type === SuggestionTypes.Mentions) {
      vxm.post.getMentionSuggestions(this.query).then((res: {data: {items: {username: string; _id: string}[]}}) => {
        const data = res.data.items;
        this.suggestionList = data && data.length ? data : [];
      });
    }
  }
  passSuggestion(suggestion: {}): void {
    if (this.type === SuggestionTypes.Hashtags) {
      this.$emit('setHashtagBySuggestion', suggestion);
    } else if (this.type === SuggestionTypes.Mentions) {
      this.$emit('setMentionBySuggestion', suggestion);
    }
  }
  formatCount(count: number): string {
    if (!count) {
      return '';
    }
    const digits = count.toString().length;
    switch (true) {
      case digits < 4:
        return `${count} publications`;
      case digits < 7:
        return `${Math.round((count / 1000 + Number.EPSILON) * 100) / 100}K publications`;
      case digits < 10:
        return `${Math.round((count / 1000000 + Number.EPSILON) * 100) / 100}M publications`;
      case digits < 13:
        return `${Math.round((count / 1000000000 + Number.EPSILON) * 100) / 100}B publications`;
      case digits < 16:
        return `${Math.round((count / 1000000000000 + Number.EPSILON) * 100) / 100}T publications`;
      default:
        return '';
    }
  }
}
