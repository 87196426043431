import {action, getter, Module, mutation, VuexModule} from 'vuex-class-component';
import router from '@/router';
import ChatApi from '@/service/chatApi';
import OauthApi from '@/service/oauthApi';
import UsersApi from '@/service/usersApi';
import MediaApi from '@/service/mediaApi';
import ContactApi from '@/service/contactApi';
import WebsitesApi from '@/service/websitesApi';
import {SignUpDataInterface} from '@/types/SignUpDataInterface';
import {UserDataInterface} from '@/types/UserDataInterface';
import GetWebsitePayloadInterface from '@/types/GetWebsitePayloadInterface';
import {GetMessagesParamsInterface} from '@/types/GetMessagesParamsInterface';
import NotificationsApi from '@/service/notificationsApi';
import RequestsApi from '@/service/requestsApi';
import {ApiMediaTypes} from '@/constants/apiMediaTypes';

@Module({namespacedPath: 'user/'})
export class UserStore extends VuexModule {
  @getter accessToken = '';
  @getter refreshToken = '';
  @getter token = '';
  @getter data = {isDarkMode: true} as UserDataInterface;
  @getter viewAsGrid = true;
  @getter notificationCounter = 0;
  @getter mentionCounter = 0;
  @getter vocabulary: string[] = [];
  @getter mutedUsers: UserDataInterface[] = [];
  @getter blockedUsers: UserDataInterface[] = [];

  get postPreferencesUnset(): boolean {
    return !this.data.wantToPost || !Object.keys(this.data.wantToPost).length;
  }

  @mutation setAuthToken(data: {accessToken: string; refreshToken: string}) {
    this.accessToken = data.accessToken;
    this.refreshToken = data.refreshToken;
  }

  @mutation setUserData(data: UserDataInterface) {
    this.data = data;
  }

  @mutation setVocabulary(data: string[]) {
    this.vocabulary = data;
  }

  @mutation reset() {
    this.data = {} as UserDataInterface;
    this.accessToken = '';
    this.refreshToken = '';
    this.blockedUsers = [];
    this.mutedUsers = [];
    router.push('/login');
  }

  @mutation setBlockedUsers(data: UserDataInterface[]) {
    this.blockedUsers = data;
  }

  @mutation setMutedUsers(data: UserDataInterface[]) {
    this.mutedUsers = data;
  }

  @mutation changeView() {
    this.viewAsGrid = !this.viewAsGrid;
  }

  @mutation setTheme(isDarkMode: boolean) {
    this.data.isDarkMode = isDarkMode;
  }

  @mutation updateNotificationCounter(payload: number) {
    this.notificationCounter = payload;
  }

  @mutation updateMentionCounter(payload: number) {
    this.mentionCounter = payload;
  }

  @action
  async signIn(data: {email: string; password: string}) {
    return OauthApi.signIn(data).then((res) => {
      this.setAuthToken(res.data.data);
      this.setUserData(res.data.data.user);
      return res;
    });
  }

  @action
  async reLogin(token: string) {
    return OauthApi.refreshToken(token).then((res) => {
      this.setAuthToken(res.data);
      return res;
    });
  }

  @action
  async signUpVerify(data: {email: string; 'g-recaptcha-response': string}) {
    return OauthApi.signUpVerify(data).then((res) => {
      return res;
    });
  }

  @action
  async signUp(payload: {data: SignUpDataInterface; accessToken: string}) {
    return OauthApi.signUp(payload).then((res) => {
      return res;
    });
  }

  @action
  async logOut(token: string) {
    this.reset();
    return OauthApi.logOut(token);
  }

  @action
  async switchView() {
    this.changeView();
  }

  @action
  async getProfileData(id: string) {
    return UsersApi.getUser(id);
  }

  @action
  async getRestrictedWords(token?: string) {
    return UsersApi.getRestrictedWords(token ? token : this.accessToken).then((res) => {
      this.setVocabulary(res.data.words);
      return res;
    });
  }

  @action
  async getWebsites(payload: GetWebsitePayloadInterface) {
    return WebsitesApi.getWebsites(payload);
  }

  @action
  async requestChangePassword(email: string) {
    return OauthApi.requestChangePassword(email);
  }

  @action
  async changePassword(payload: {data: {password: string; password2: string}; token: string}) {
    return OauthApi.changePassword(payload);
  }

  @action
  async updateUser(payload: {}) {
    return UsersApi.updateUser(payload).then((res: {data: {data: UserDataInterface}}) => {
      this.setUserData(res.data.data);
      return res;
    });
  }

  @action
  async updateAvatar(payload: {files: File[]}) {
    return MediaApi.newMedia({files: payload.files, contentType: ApiMediaTypes.Avatar});
  }

  @action
  async getPosts(payload: {id: string; page: number; limit: number}) {
    return UsersApi.getPosts(payload);
  }

  @action
  async contactUs(data: {email: string; text: string}) {
    return ContactApi.contactUs(data);
  }

  @action
  async follow(payload: {}) {
    return UsersApi.follow(payload);
  }

  @action
  async getFollowers(payload: {id: string; page: number; limit: number}) {
    return UsersApi.getFollowers(payload);
  }

  @action
  async getFollowing(payload: {id: string; page: number; limit: number}) {
    return UsersApi.getFollowing(payload);
  }

  @action
  async switchTheme(payload: {isDarkMode: boolean}) {
    return UsersApi.updateUser(payload).then(() => {
      this.setTheme(payload.isDarkMode);
    });
  }

  @action
  async getRooms(params: {page: number; limit: number}) {
    return ChatApi.getRooms(params);
  }

  @action
  async getRoom(id: string) {
    return ChatApi.getRoom(id);
  }

  @action
  async createRoom(data: {userId: string}) {
    return ChatApi.createRoom(data);
  }

  @action
  async deleteRoom(id: string) {
    return ChatApi.deleteRoom(id);
  }

  @action
  async getMessages(payload: {id: string; params: GetMessagesParamsInterface}) {
    return ChatApi.getMessages(payload.id, payload.params);
  }

  @action
  async createMessage(payload: {id: string; data: {message: string; medias: string[]}}) {
    return ChatApi.createMessage(payload.id, payload.data);
  }

  @action
  async deleteMessage(payload: {roomId: string; messageId: string}) {
    return ChatApi.deleteMessage(payload.roomId, payload.messageId);
  }

  @action
  async getNotifications(payload: {page: number; limit: number; offset: number; type: string}) {
    return NotificationsApi.getNotifications(payload);
  }

  @action
  async deleteNotification(id: string) {
    return NotificationsApi.deleteNotification(id);
  }

  @action
  async answerFollowRequest(payload: {followerId: string; accepted: boolean}) {
    return UsersApi.answerFollowRequest(payload);
  }

  @action
  async setNotificationCounter(payload: number) {
    this.updateNotificationCounter(payload);
  }

  @action
  async resetNotificationCounter() {
    this.updateNotificationCounter(0);
  }

  @action
  async setMentionCounter(payload: number) {
    this.updateMentionCounter(payload);
  }

  @action
  async resetMentionCounter() {
    this.updateMentionCounter(0);
  }

  @action
  async requestJoin(payload: {name: string; email: string; phone?: string; comment?: string}) {
    return RequestsApi.request(payload);
  }

  @action
  async addAvatarOnRegistration(payload: {files: File[]; accessToken: string}) {
    return MediaApi.newMedia({
      files: payload.files,
      contentType: ApiMediaTypes.Avatar,
      accessToken: payload.accessToken,
    });
  }

  @action
  async checkUsernameUniqueness(username: string) {
    return OauthApi.checkUsernameUniqueness(username);
  }

  @action
  async getRecommendedBlogs() {
    return UsersApi.getRecommendedBlogs();
  }

  @action
  async getEnums(accessToken: string | undefined) {
    return UsersApi.getEnums(accessToken);
  }

  @action
  async reportUser(params: {userId: string; type: string; reason?: string}) {
    return UsersApi.reportUser(params);
  }

  @action
  async getBlockedUsers() {
    return UsersApi.getBlockedUsers().then((res) => {
      this.setBlockedUsers(res.data.data);
      return res;
    });
  }

  @action
  async blockUser(userId: string) {
    return UsersApi.blockUser(userId).then(() => {
      this.getBlockedUsers();
    });
  }

  @action
  async unblockUser(userId: string) {
    return UsersApi.unblockUser(userId).then(() => {
      this.getBlockedUsers();
    });
  }

  @action
  async getMutedUsers() {
    return UsersApi.getMutedUsers().then((res) => {
      this.setMutedUsers(res.data.data);
      return res;
    });
  }

  @action
  async muteUser(userId: string) {
    return UsersApi.muteUser(userId).then(() => {
      this.getMutedUsers();
    });
  }

  @action
  async unmuteUser(userId: string) {
    return UsersApi.unmuteUser(userId).then(() => {
      this.getMutedUsers();
    });
  }
}
