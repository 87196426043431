import { render, staticRenderFns } from "./CreatePostFloatingButton.vue?vue&type=template&id=54750809&scoped=true&lang=pug&"
import script from "./CreatePostFloatingButton.vue?vue&type=script&lang=ts&"
export * from "./CreatePostFloatingButton.vue?vue&type=script&lang=ts&"
import style0 from "./CreatePostFloatingButton.vue?vue&type=style&index=0&id=54750809&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54750809",
  null
  
)

export default component.exports