import api from './api';
import {PreSignedPostInterface} from '@/types/PreSignedPostInterface';
import {vxm} from '@/store';
import {ApiMediaTypes} from '@/constants/apiMediaTypes';
import SocketIO from 'socket.io-client';

export default {
  newMedia(payload: {
    files: File[];
    contentType: ApiMediaTypes;
    duration?: number;
    startTime?: number;
    onUploadProgressEvent?: (progressEvent: ProgressEvent) => void;
    accessToken?: string;
  }) {
    const s3UploadArray: Array<Promise<object>> = [];
    let totalProgress = 0;
    const totalSize = payload.files.reduce((a, b) => a + b.size, 0);
    payload.files.forEach((file) => {
      let loaded = 0;
      s3UploadArray.push(
        this.s3FileUpload(
          {
            fileKey: file.name,
            contentType: payload.contentType,
            type: file.type.split('/')[0],
          },
          file,
          function progressEvent(event: ProgressEvent) {
            if (payload.onUploadProgressEvent) {
              totalProgress += event.loaded - loaded;
              loaded = event.loaded;
              payload.onUploadProgressEvent({loaded: totalProgress, total: totalSize} as ProgressEvent);
            }
          },
          payload.accessToken,
        ),
      );
    });
    return Promise.all(s3UploadArray).then((res) => {
      let config = undefined;
      if (payload.accessToken) {
        config = {
          headers: {
            Authorization: `Bearer ${payload.accessToken}`,
          },
        };
      }

      return api
        .post(
          '/media',
          {type: payload.contentType, duration: payload.duration, startTime: payload.startTime, fileTokens: res},
          config,
        )
        .then(async (res) => {
          if (vxm.user.accessToken) {
            const mediaIDs = res.data.data.map((media: {_id: string}) => media._id);
            const socket = SocketIO(process.env.VUE_APP_SOCKET_URL, {
              query: {token: vxm.user.accessToken},
            });
            const promise = new Promise((resolve) => {
              socket.on('media:ready', (res: {_id: string; transcoding: {status: string}}) => {
                if (res.transcoding.status === 'COMPLETED' && mediaIDs.includes(res._id)) {
                  mediaIDs.splice(mediaIDs.indexOf(res._id), 1);
                }
                if (!mediaIDs.length) {
                  resolve();
                }
              });
            });
            await promise;
            socket.disconnect();
          }
          return res;
        });
    });
  },
  deleteMedia(id: string) {
    return api.delete(`/media/${id}`);
  },
  async s3FileUpload(
    data: PreSignedPostInterface,
    file: File,
    uploadProgressCallback?: Function,
    accessToken?: string,
  ) {
    let config = undefined;
    if (accessToken) {
      config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
    }
    const {urls, fileKey, fileToken, chunkSize, uploadId} = await api
      .post(
        `/aws/multipart-upload/init`,
        {
          fileKey: data.fileKey,
          contentType: data.contentType,
          type: data.type,
          fileSize: file.size,
          chunkSize: 10 * 1024 * 1024,
        },
        config,
      )
      .then((res) => res.data);
    let url;
    let start = 0;
    const parts: Array<{url: string; slice: Blob; progressEvent: Function}> = [];
    let totalProgress = 0;
    while ((url = urls.shift())) {
      let loaded = 0;
      parts.push({
        url: url,
        slice: file.slice(start, Math.min(file.size, start + chunkSize)),
        progressEvent: function progressEvent(event: ProgressEvent) {
          if (uploadProgressCallback) {
            totalProgress += event.loaded - loaded;
            loaded = event.loaded;
            uploadProgressCallback({loaded: totalProgress, total: file.size});
          }
        },
      });
      start += chunkSize;
    }
    if ((await this.s3FileUploadParts(parts)) === 'success') {
      return api.post(`/aws/multipart-upload/complete`, {fileKey: fileKey, uploadId}, config).then(() => {
        return fileToken;
      });
    }
    return api.post(`/aws/multipart-upload/abort`, {fileKey: fileKey, uploadId}, config).then(() => {
      return null;
    });
  },
  async s3FileUploadParts(parts: Array<{url: string; slice: Blob; progressEvent: Function}>): Promise<string> {
    const failedParts: Array<{url: string; slice: Blob; progressEvent: Function}> = [];
    const promises: Array<Promise<any>> = [];
    for (let i = 0; i <= parts.length - 1; i++) {
      promises.push(
        this.s3Upload(parts[i].url, parts[i].slice, parts[i].progressEvent).catch(() => {
          failedParts.push(parts[i]);
        }),
      );
      if (promises.length === 10) {
        await Promise.all(promises);
        promises.splice(0, promises.length);
      }
      if (vxm.files.abortUpload) {
        break;
      }
    }
    await Promise.all(promises);
    if (vxm.files.abortUpload) {
      return 'abort';
    } else if (failedParts.length) {
      vxm.files.setShowUploadErrorModal(true);
      if (await vxm.files.uploadErrorAction) {
        return this.s3FileUploadParts(failedParts);
      } else {
        return 'error';
      }
    }
    return 'success';
  },
  s3Upload(url: string, slice: Blob, progressCallback: Function) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', url, true);
      xhr.onload = function() {
        if (this.status === 200) {
          resolve();
        } else {
          reject();
        }
      };
      xhr.onerror = function() {
        reject();
      };
      xhr.upload.onprogress = function(event) {
        progressCallback(event);
        if (vxm.files.abortUpload) {
          xhr.abort();
          reject();
        }
      };
      xhr.send(slice);
    });
  },
};
