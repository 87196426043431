






























import {Component, Vue, Watch} from 'vue-property-decorator';
import {vxm} from '@/store';
import HelpCenter from '@/components/modals/HelpCenter.vue';
import {Themes} from '@/constants/themes';
import clickInside from '@/utils/clickInside';

@Component({
  components: {HelpCenter},
  directives: {clickInside},
})
export default class HeaderMenu extends Vue {
  showMenu = false;
  username = vxm.user.data.username;
  invitationsAvailable = -1;

  get isLightTheme(): boolean {
    return !vxm.user.data.isDarkMode;
  }
  get invitationsMessage(): string {
    return this.invitationsAvailable >= 0 ? `My Invitations (${this.invitationsAvailable})` : 'My Invitations';
  }

  mounted() {
    document.body.setAttribute('theme', this.isLightTheme ? Themes.Light : Themes.Dark);
    this.getInvitationsAvailable();
  }
  @Watch('isLightTheme')
  onThemeChange() {
    document.body.setAttribute('theme', this.isLightTheme ? Themes.Light : Themes.Dark);
  }
  switchTheme() {
    vxm.user.switchTheme({isDarkMode: !vxm.user.data.isDarkMode}).then(() => {
      document.body.setAttribute('theme', vxm.user.data.isDarkMode ? Themes.Dark : Themes.Light);
    });
  }
  goTo(link: string) {
    this.showMenu = false;
    if (this.$route.path !== link) {
      this.$router.push(link);
    }
  }
  logout() {
    vxm.user.logOut(vxm.user.accessToken);
    this.$socket.disconnect();
    this.$router.push({name: 'login'});
  }
  closeMenu(): void {
    this.showMenu = false;
  }
  getInvitationsAvailable(): void {
    vxm.invitations.getInvitationsAvailable().then((available: number) => {
      this.invitationsAvailable = available;
    });
  }
}
