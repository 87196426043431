







































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import moment from 'moment';
import Loader from '@/components/Loader.vue';
import clickInside from '@/utils/clickInside';

@Component({
  name: 'VideoTrimmer',
  components: {Loader, VueSlider},
  directives: {
    clickInside,
  },
})
export default class VideoTrimmer extends Vue {
  @Prop({type: String, required: true}) readonly videoUrl!: string;

  loading = false;
  video = {} as any;
  volume = 0;
  hasAudio = false;
  duration = 0;
  formattedDuration = '';
  formattedCurrentTime = '';
  timeFormat = '';
  playbackBar = {} as HTMLElement;
  playbackRect = {} as DOMRect;
  seekableBar = {} as HTMLElement;
  seekRatio = 0;
  progressBar = {} as HTMLElement;
  startGrabber = {} as HTMLElement;
  endGrabber = {} as HTMLElement;
  frontTrimmedRatio = 0;
  endTrimmedRatio = 1;
  maxRangeSeconds = 250;
  maxRange = 0;
  minRange = 0;
  minFrontRatio = 0;
  maxEndRatio = 1;
  playerError = false;
  inputFrontTime = '00:00:00';
  inputEndTime = '00:02:30';
  player = {
    video: {} as HTMLVideoElement,
    start: 0,
    end: 0,
  };
  passive = {passive: true} as AddEventListenerOptions & EventListenerOptions;

  get formattedTimeInfo(): string {
    return `${this.formattedCurrentTime} / ${this.formattedDuration}`;
  }
  get formattedStartTime(): string {
    const format = moment.duration(this.frontTrimmedRatio * this.duration).hours() ? 'HH:mm:ss' : 'mm:ss';
    return moment.utc(this.frontTrimmedRatio * this.duration).format(format);
  }
  get formattedEndTime(): string {
    const format = moment.duration(this.endTrimmedRatio * this.duration).hours() ? 'HH:mm:ss' : 'mm:ss';
    return moment.utc(this.endTrimmedRatio * this.duration).format(format);
  }
  get formattedDurationTime(): string {
    const format = moment.duration((this.endTrimmedRatio - this.frontTrimmedRatio) * this.duration).hours()
      ? 'HH:mm:ss'
      : 'mm:ss';
    return moment.utc(Math.round((this.endTrimmedRatio - this.frontTrimmedRatio) * this.duration)).format(format);
  }

  @Watch('videoUrl')
  onVideoUrlChange() {
    this.initTrimmer();
  }
  @Watch('volume')
  onVolumeChange() {
    if (this.video) {
      this.video.volume = this.volume;
    }
  }
  @Watch('frontTrimmedRatio')
  onFrontTrimmedRatioChange() {
    if (this.frontTrimmedRatio + this.maxRange > this.endTrimmedRatio) {
      this.maxEndRatio = this.frontTrimmedRatio + this.maxRange > 1 ? 1 : this.frontTrimmedRatio + this.maxRange;
    }
  }
  @Watch('endTrimmedRatio')
  onEndTrimmedRatioChange() {
    if (this.endTrimmedRatio - this.maxRange < this.frontTrimmedRatio) {
      this.minFrontRatio = this.endTrimmedRatio - this.maxRange < 0 ? 0 : this.endTrimmedRatio - this.maxRange;
    }
  }

  @Watch('inputFrontTime')
  onInputFrontTimeChange() {
    const frontTime = new Date('1970-01-01T' + this.inputFrontTime + 'Z').getTime() / 1000;
    const endTime = new Date('1970-01-01T' + this.inputEndTime + 'Z').getTime() / 1000;
    if (endTime < frontTime) {
      this.inputEndTime = new Date(frontTime * 1000).toISOString().substr(11, 8);
    } else if (endTime - frontTime >= 150) {
      this.inputEndTime = new Date((frontTime + 150) * 1000).toISOString().substr(11, 8);
    }
  }

  @Watch('inputEndTime')
  onInputEndTime() {
    const frontTime = new Date('1970-01-01T' + this.inputFrontTime + 'Z').getTime() / 1000;
    const endTime = new Date('1970-01-01T' + this.inputEndTime + 'Z').getTime() / 1000;
    if (endTime < frontTime) {
      this.inputFrontTime = new Date(endTime * 1000).toISOString().substr(11, 8);
    } else if (endTime - frontTime >= 150) {
      this.inputFrontTime = new Date((endTime - 150) * 1000).toISOString().substr(11, 8);
    }
  }

  async mounted() {
    // set this parameter to none to prevent browser history navigation while dragging ui elements
    ((document.body as unknown) as {style: {overscrollBehaviorX: string}}).style.overscrollBehaviorX = 'none';
    ((document.documentElement as unknown) as {style: {overscrollBehaviorX: string}}).style.overscrollBehaviorX =
      'none';
    this.initTrimmer();
    this.seekableBar.addEventListener('mousedown', this.onSeekablePress);
    this.seekableBar.addEventListener('touchstart', this.onSeekablePress, this.passive);
    this.startGrabber.addEventListener('mousedown', this.onStartGrabberPress);
    this.startGrabber.addEventListener('touchstart', this.onStartGrabberPress, this.passive);
    this.endGrabber.addEventListener('mousedown', this.onEndGrabberPress);
    this.endGrabber.addEventListener('touchstart', this.onEndGrabberPress, this.passive);
  }
  beforeDestroy() {
    ((document.body as unknown) as {style: {overscrollBehaviorX: string}}).style.overscrollBehaviorX = 'auto';
    ((document.documentElement as unknown) as {style: {overscrollBehaviorX: string}}).style.overscrollBehaviorX =
      'auto';
    this.seekableBar.removeEventListener('mousedown', this.onSeekablePress);
    this.seekableBar.removeEventListener('touchstart', this.onSeekablePress, this.passive);
    this.startGrabber.removeEventListener('mousedown', this.onStartGrabberPress);
    this.startGrabber.removeEventListener('touchstart', this.onStartGrabberPress, this.passive);
    this.endGrabber.removeEventListener('mousedown', this.onEndGrabberPress);
    this.endGrabber.removeEventListener('touchstart', this.onEndGrabberPress, this.passive);
  }

  initTrimmer(): void {
    this.video = document.getElementsByClassName('preview')[0] as HTMLVideoElement;
    this.playbackBar = document.getElementsByClassName('playback')[0] as HTMLElement;
    this.playbackRect = {} as DOMRect;
    this.seekableBar = document.getElementsByClassName('seekable')[0] as HTMLElement;
    this.seekRatio = 0;
    this.progressBar = document.getElementsByClassName('progress')[0] as HTMLElement;
    this.startGrabber = document.getElementsByClassName('start')[0] as HTMLElement;
    this.endGrabber = document.getElementsByClassName('end')[0] as HTMLElement;
    this.frontTrimmedRatio = 0;
    this.endTrimmedRatio = 1;
    this.maxRangeSeconds = 150;
    this.maxRange = 0;
    this.minFrontRatio = 0;
    this.maxEndRatio = 1;

    this.player = {
      video: document.getElementsByClassName('preview')[0] as HTMLVideoElement,
      start: 0,
      end: 0,
    };
  }
  async onVideoLoaded() {
    this.duration = this.video.duration * 1000;
    this.timeFormat = moment.duration(this.duration).hours() ? 'HH:mm:ss' : 'mm:ss';
    this.formattedDuration = moment.utc(this.duration).format(this.timeFormat);
    this.player.end = this.player.video.duration;
    this.maxRange = this.maxRangeSeconds / this.video.duration;
    this.minRange = 1 / this.video.duration;
    this.maxEndRatio = this.maxRange > 1 ? 1 : this.maxRange;
    this.endTrimmedRatio = this.maxEndRatio;
    this.endGrabber.style.right = this.maxEndRatio < 1 ? `${(1 - this.maxEndRatio) * 100}%` : '0';
    this.updateSeekableEnd();
    this.video.volume = this.volume;
    await this.video.play();
    await new Promise((r) => setTimeout(r, 150));
    this.video.pause();
    this.hasAudio =
      this.video.mozHasAudio ||
      this.video.webkitAudioDecodedByteCount ||
      (this.video.audioTracks && this.video.audioTracks.length);
    this.video.currentTime = 0;
    this.loading = false;
  }
  playVideo() {
    if (this.player.video.currentTime >= this.player.end) {
      this.player.video.currentTime = this.frontTrimmedRatio * this.video.duration;
    }
    if (this.player.video.paused) {
      this.player.video.play();
    } else {
      this.pauseVideo();
    }
  }
  onPlayTimeUpdate() {
    this.seekRatio = this.video.currentTime / this.video.duration;
    this.updateProgressBarWidth();
    if (this.player.video.currentTime >= this.player.end) {
      this.pauseVideo();
    }
    this.formattedCurrentTime = moment.utc(this.video.currentTime * 1000).format(this.timeFormat);
  }
  pauseVideo() {
    this.player.video.pause();
    if (this.progressBar.clientWidth >= this.seekableBar.clientWidth) {
      this.progressBar.style.setProperty('width', `${this.seekableBar.clientWidth}px`);
    }
  }
  onSeekablePress(event: MouseEvent | TouchEvent) {
    if ('buttons' in event && event.buttons !== 1) {
      return;
    }
    this.playbackRect = this.playbackBar.getBoundingClientRect();
    const clientX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
    const seekAmount = (clientX - this.playbackRect.left) / this.playbackRect.width;
    this.seekVideo(seekAmount);
    window.addEventListener('mousemove', this.playheadMove);
    window.addEventListener('touchmove', this.playheadMove);
    window.addEventListener('mouseup', this.playheadDrop);
    window.addEventListener('touchend', this.playheadDrop);
  }
  seekVideo(seekRatio: number) {
    this.video.currentTime = this.video.duration * seekRatio;
  }
  updateProgressBarWidth() {
    this.progressBar.style.width = (this.seekRatio - this.frontTrimmedRatio) * 100 + '%';
  }
  updateProgressBarPosition() {
    this.progressBar.style.left = this.frontTrimmedRatio * 100 + '%';
  }
  updateSeekableStart() {
    this.player.start = this.player.video.duration * this.frontTrimmedRatio;
    this.seekableBar.style.left = this.frontTrimmedRatio * 100 + '%';
    this.startGrabber.style.left = this.frontTrimmedRatio * 100 + '%';
    this.updateProgressBarWidth();
  }
  updateSeekableEnd() {
    this.player.end = this.player.video.duration * this.endTrimmedRatio;
    this.seekableBar.style.right = this.endTrimmedRatio < 1 ? (1 - this.endTrimmedRatio) * 100 + '%' : '0';
    this.endGrabber.style.right = this.endTrimmedRatio < 1 ? (1 - this.endTrimmedRatio) * 100 + '%' : '0';
    this.updateProgressBarWidth();
  }
  playheadMove(event: MouseEvent | TouchEvent) {
    const clientX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
    const seekAmount = (clientX - this.playbackRect.left) / this.playbackRect.width;
    this.seekVideo(seekAmount);
    window.addEventListener('mouseup', this.playheadDrop);
    window.addEventListener('touchend', this.playheadDrop);
  }
  playheadDrop() {
    window.removeEventListener('mousemove', this.playheadMove);
    window.removeEventListener('touchmove', this.playheadMove);
    window.removeEventListener('mouseup', this.playheadDrop);
    window.removeEventListener('touchend', this.playheadDrop);
  }
  onStartGrabberPress(event: MouseEvent | TouchEvent) {
    if ('buttons' in event && event.buttons !== 1) {
      return;
    }
    this.playbackRect = this.playbackBar.getBoundingClientRect();
    window.addEventListener('mousemove', this.startGrabberMove);
    window.addEventListener('touchmove', this.startGrabberMove);
    window.addEventListener('mouseup', this.startGrabberDrop);
    window.addEventListener('touchend', this.startGrabberDrop);
  }
  startGrabberMove(event: MouseEvent | TouchEvent) {
    const clientX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
    const ratio = (clientX - this.playbackRect.left) / this.playbackRect.width;
    if (ratio <= this.minFrontRatio) {
      this.frontTrimmedRatio = this.minFrontRatio;
    } else if (ratio <= 0) {
      this.frontTrimmedRatio = 0;
    } else if (ratio >= this.endTrimmedRatio) {
      this.frontTrimmedRatio = this.endTrimmedRatio - this.minRange;
    } else {
      this.frontTrimmedRatio = ratio;
    }
    this.updateSeekableStart();
    this.seekVideo(this.frontTrimmedRatio);
    this.updateProgressBarPosition();
    this.progressBar.style.width = '0';
  }
  startGrabberDrop() {
    window.removeEventListener('mousemove', this.startGrabberMove);
    window.removeEventListener('touchmove', this.startGrabberMove);
    window.removeEventListener('mouseup', this.startGrabberDrop);
    window.removeEventListener('touchend', this.startGrabberDrop);
  }
  onEndGrabberPress(event: MouseEvent | TouchEvent) {
    if ('buttons' in event && event.buttons !== 1) {
      return;
    }
    this.playbackRect = this.playbackBar.getBoundingClientRect();
    window.addEventListener('mousemove', this.endGrabberMove);
    window.addEventListener('touchmove', this.endGrabberMove);
    window.addEventListener('mouseup', this.endGrabberDrop);
    window.addEventListener('touchend', this.endGrabberDrop);
  }
  endGrabberMove(event: MouseEvent | TouchEvent) {
    const clientX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
    const ratio = (clientX - this.playbackRect.left) / this.playbackRect.width;
    if (ratio >= this.maxEndRatio) {
      this.endTrimmedRatio = this.maxEndRatio;
    } else if (ratio >= 1) {
      this.endTrimmedRatio = 1;
    } else if (ratio <= this.frontTrimmedRatio) {
      this.endTrimmedRatio = this.frontTrimmedRatio + this.minRange;
    } else {
      this.endTrimmedRatio = ratio;
    }
    this.updateSeekableEnd();
    this.seekVideo(this.endTrimmedRatio);
  }
  endGrabberDrop() {
    window.removeEventListener('mousemove', this.endGrabberMove);
    window.removeEventListener('touchmove', this.endGrabberMove);
    window.removeEventListener('mouseup', this.endGrabberDrop);
    window.removeEventListener('touchend', this.endGrabberDrop);
  }

  cancelTrim(): void {
    this.$emit('trimCancelled');
  }
  confirmTrim(): void {
    this.player.video.pause();
    let startTime: number;
    let duration: number;
    if (this.playerError) {
      startTime = new Date('1970-01-01T' + this.inputFrontTime + 'Z').getTime() / 1000;
      duration = new Date('1970-01-01T' + this.inputEndTime + 'Z').getTime() / 1000 - startTime;
    } else {
      startTime = Math.round((this.frontTrimmedRatio * this.duration) / 1000);
      duration = Math.round(((this.endTrimmedRatio - this.frontTrimmedRatio) * this.duration) / 1000);
    }
    this.$emit('trimConfirmed', {startTime, duration});
  }
  onError(): void {
    this.playerError = true;
  }
}
