


































































import {Component, Vue} from 'vue-property-decorator';
import {vxm} from '@/store';
import {Themes} from '@/constants/themes';
import clickOutside from '@/utils/clickOutsideElement';
import NewPost from '@/components/modals/NewPost.vue';
import ProfileActivityPreferencesModal from '@/components/modals/ProfileActivityPreferencesModal.vue';

@Component({
  components: {NewPost, ProfileActivityPreferencesModal},
  directives: {
    clickOutside,
  },
})
export default class DesktopAsideNav extends Vue {
  messageCounter = 0;
  showMoreMenu = false;
  username = vxm.user.data.username;
  showProfileActivityPreferencesModal = false;

  get isLightTheme(): boolean {
    return !vxm.user.data.isDarkMode;
  }
  get invitationsAvailable(): number {
    return vxm.invitations.invitations.available;
  }
  get unreadNotificationsCounter(): number {
    return vxm.user.notificationCounter + vxm.user.mentionCounter;
  }

  mounted() {
    this.sockets.subscribe('read', (res: {global: number}) => {
      this.messageCounter = res.global;
    });
    this.sockets.subscribe('message', () => {
      this.messageCounter++;
    });
    vxm.invitations.getInvitationsAvailable();
  }

  openNewPostModal() {
    if (vxm.user.postPreferencesUnset) {
      this.showProfileActivityPreferencesModal = true;
      return;
    }
    vxm.post.setShowNewPostModal(true);
  }
  switchTheme() {
    vxm.user.switchTheme({isDarkMode: !vxm.user.data.isDarkMode}).then(() => {
      document.body.setAttribute('theme', vxm.user.data.isDarkMode ? Themes.Dark : Themes.Light);
    });
  }

  destroyed() {
    this.sockets.unsubscribe('read');
    this.sockets.unsubscribe('message');
  }

  logout() {
    vxm.user.logOut(vxm.user.accessToken);
    this.$socket.disconnect();
    this.$router.push({name: 'login'});
  }

  closeMoreMenu() {
    this.showMoreMenu = false;
  }

  closeProfileActivityPreferencesModal(): void {
    this.showProfileActivityPreferencesModal = false;
  }

  onUserUpdated(): void {
    this.showProfileActivityPreferencesModal = false;
    this.openNewPostModal();
  }
}
