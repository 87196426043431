import {action, getter, Module, mutation, VuexModule} from 'vuex-class-component';
import SearchApi from '@/service/searchApi';
import PeopleInterface from '@/types/PeopleInterface';

@Module({namespacedPath: 'search/'})
export class SearchStore extends VuexModule {
  @getter lastSearch = [] as PeopleInterface[];

  @mutation setLastSearch(payload: PeopleInterface[]) {
    this.lastSearch = payload;
  }

  @mutation clearLastSearch() {
    this.lastSearch = [];
    SearchApi.deleteSearch();
  }

  @action
  async saveLastSearch(payload: any) {
    SearchApi.latestSearch(payload).then((res) => {
      this.setLastSearch(res.data.data.searchHistory);
    });
  }

  @action
  async clearAllLastSearch() {
    this.clearLastSearch();
  }

  @action
  async doSearch(payload: {}) {
    return SearchApi.doSearch(payload);
  }
}
