export enum BrowserNames {
  Chrome = 'Chrome',
  Firefox = 'Firefox',
  Opera = 'Opera',
  Safari = 'Safari',
  Edge = 'Edge',
}

export type BrowserNameType =
  | BrowserNames.Chrome
  | BrowserNames.Firefox
  | BrowserNames.Opera
  | BrowserNames.Safari
  | BrowserNames.Edge;
