


















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Vuelidate from 'vuelidate';
import {maxLength} from 'vuelidate/lib/validators';
import BhInput from '@/components/BhInput.vue';
import {vxm} from '@/store';

Vue.use(Vuelidate);

@Component({
  components: {BhInput},
  validations: {
    form: {
      postAndShareClothedPreferencesOther: {maxLength: maxLength(25)},
    },
  },
})
export default class ProfileActivityPreferences extends Vue {
  @Prop({
    type: String,
    default: 'The primary use of my profile here is',
  })
  readonly title!: string;
  @Prop({type: Object, required: true}) readonly wantToPost!: {[key: string]: {[key: string]: string}};
  @Prop({type: Boolean, default: false}) readonly isUpdating!: boolean;

  wantToPostOptions = {
    inClothes: 'inCloth',
    withoutClothes: 'withoutCloth',
  };
  form = {
    viewOnly: true,
    wantToPostType: '',
    postAndShareNude: null as null | boolean,
    postAndShareNudePreference: '',
    postAndShareClothedPreference: '',
    postAndShareClothedPreferencesOther: '',
  };

  get postAndShareClothedPreferencesOtherErrorMessage(): string {
    return this.postAndShareClothedPreferencesOtherError
      ? 'This field should not be empty'
      : `This field should be up to ${this.$v.form.postAndShareClothedPreferencesOther?.$params.maxLength.max} symbols long`;
  }
  get postAndShareClothedPreferencesOtherError(): boolean {
    return (
      !this.form.postAndShareNude &&
      this.form.postAndShareClothedPreference === 'Other' &&
      !this.form.postAndShareClothedPreferencesOther
    );
  }

  @Watch('form.wantToPostType')
  onPostTypeChanged() {
    if (this.form.wantToPostType === this.wantToPostOptions.inClothes) {
      this.form.postAndShareNude = false;
    } else if (this.form.wantToPostType === this.wantToPostOptions.withoutClothes) {
      this.form.postAndShareNude = true;
    }
  }
  @Watch('form', {deep: true})
  onFormChanged() {
    this.passUpdatedPreferences();
  }

  created() {
    if (this.isUpdating) {
      if (vxm.user.data.wantToPost) {
        if (Object.keys(vxm.user.data.wantToPost).length) {
          this.form.viewOnly = false;
          if (vxm.user.data.wantToPost.inCloth) {
            this.form.postAndShareNude = false;
            this.form.wantToPostType = this.wantToPostOptions.inClothes;
            this.form.postAndShareClothedPreference = vxm.user.data.wantToPost.inCloth;
            if (vxm.user.data.wantToPost.inCloth === this.wantToPost.inCloth.other) {
              this.form.postAndShareClothedPreferencesOther = vxm.user.data.otherWantToPost || '';
            }
          } else if (vxm.user.data.wantToPost.withoutCloth) {
            this.form.postAndShareNude = true;
            this.form.wantToPostType = this.wantToPostOptions.withoutClothes;
            this.form.postAndShareNudePreference = vxm.user.data.wantToPost.withoutCloth;
          }
        }
      }
    }
  }

  getFormattedValue(key: string): string {
    if (key === 'inCloth') {
      return 'With clothing';
    }
    if (key === 'withoutCloth') {
      return 'Without clothing (nudity/sex)';
    }
    return key;
  }
  passUpdatedPreferences(): void {
    const wantToPost = {} as {[key: string]: string | undefined};
    if (!this.form.viewOnly) {
      if (this.form.wantToPostType === this.wantToPostOptions.inClothes) {
        wantToPost.inCloth = this.form.postAndShareClothedPreference;
      } else if (this.form.wantToPostType === this.wantToPostOptions.withoutClothes) {
        wantToPost.withoutCloth = this.form.postAndShareNudePreference;
      }
    }
    const params = {
      wantToPost,
      otherWantToPost:
        this.form.postAndShareClothedPreference === 'Other' ? this.form.postAndShareClothedPreferencesOther : undefined,
      gotErrors: false,
    };
    params.gotErrors = this.gotErrors(params);
    this.$emit('profileActivityPreferencesUpdated', params);
  }
  gotErrors(params: {otherWantToPost?: string; wantToPost: {[key: string]: string | undefined}}): boolean {
    if (this.form.viewOnly) {
      return false;
    }
    return (
      (!this.form.viewOnly && !this.form.wantToPostType) ||
      (this.form.wantToPostType === this.wantToPostOptions.inClothes && !params.wantToPost.inCloth) ||
      (this.form.wantToPostType === this.wantToPostOptions.withoutClothes && !params.wantToPost.withoutCloth) ||
      (params.wantToPost.inCloth === this.wantToPost.inCloth.other &&
        (this.$v.form.postAndShareClothedPreferencesOther?.$error || this.postAndShareClothedPreferencesOtherError))
    );
  }
}
