import api from './api';

const NotificationsApi = {
  getNotifications(payload: {page: number; limit: number; offset: number; type: string}) {
    return api.get('/notifications', {
      params: payload,
    });
  },
  deleteNotification(id: string) {
    return api.delete(`/notifications/${id}`);
  },
};

export default NotificationsApi;
