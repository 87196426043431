import api from './api';

const InvitationsApi = {
  getInvitationsAvailable() {
    return api.get('/invites/my');
  },
  checkInvitation(inviteToken: string) {
    return api.get('invites/check', {
      params: {token: inviteToken},
    });
  },
  getNewInvitation() {
    return api.get('invites/generate');
  },
};

export default InvitationsApi;
