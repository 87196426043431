import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './scss/styles.scss';
import PortalVue from 'portal-vue';
import Toasted from 'vue-toasted';
import * as Sentry from '@sentry/vue';
import {Integrations} from '@sentry/tracing';

const ToastedOptions = {
  position: 'bottom-center',
  duration: 5000,
};

Vue.config.productionTip = false;
Vue.use(PortalVue);
Vue.use(Toasted, ToastedOptions);

// if (process.env.NODE_ENV === 'production') {
Sentry.init({
  Vue,
  dsn: 'https://edc5e0134f9b4520a0caab32fe44209d@sentry.4my.fans/5',
  environment: 'staging',
  tracingOptions: {
    trackComponents: true,
  },
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  logErrors: true,
  ignoreErrors: [
    'top.GLOBALS',
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    `Can't find variable: ZiteReader`,
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    'fb_xd_fragment',
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    'conduitPage',
  ],
  denyUrls: [
    /graph\.facebook\.com/i,
    /connect\.facebook\.net\/en_US\/all\.js/i,
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extensions:\/\//i,
    /127\.0\.0\.1:4001\/isrunning/i,
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});
// }

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
