













































import {Component, Vue, Watch} from 'vue-property-decorator';
import {mapState} from 'vuex';
import VueScrollTo from 'vue-scrollto';
import {vxm} from '@/store';
import HeaderMenu from '@/components/HeaderMenu.vue';
import AutoSuggest from '@/components/AutoSuggest.vue';
import clickOutside from '@/utils/clickOutsideElement';

Vue.use(VueScrollTo);

@Component({
  components: {HeaderMenu, AutoSuggest},
  computed: {...mapState('search', ['lastSearch'])},
  directives: {
    clickOutside,
  },
})
export default class Header extends Vue {
  search = false;
  showAutoSuggest = false;
  showLastSearch = false;
  lastSearchCount = 0;
  haveSuggestions = false;
  searchInput = '';
  lastSearch!: any;
  focusedSuggestionOnMessages = 0;
  focusedSuggestionIndex = 0;
  routeList = ['popular', 'latest', 'people', 'photo', 'video'] as string[];
  messageCounter = 0;

  get isLoggedIn(): boolean {
    return !!vxm.user.accessToken;
  }
  get unreadNotifications(): boolean {
    return vxm.user.notificationCounter || vxm.user.mentionCounter;
  }
  get logoLink(): {name: string} {
    return this.isLoggedIn ? {name: 'home'} : {name: 'landing'};
  }

  mounted() {
    window.addEventListener('keyup', (e) => {
      this.$nextTick();
      if (e.code === 'ArrowDown') {
        this.changeFocusedElement(1);
      } else if (e.code === 'ArrowUp') {
        this.changeFocusedElement(-1);
      }
    });
    this.lastSearchCount = this.lastSearch.length;
    this.sockets.subscribe('read', (res: {global: number}) => {
      this.messageCounter = res.global;
    });
    this.sockets.subscribe('message', () => {
      this.messageCounter++;
    });
    this.$socket.emit('message:fetchCounts');
  }
  beforeDestroy() {
    window.removeEventListener('keyup', (e) => {
      this.$nextTick();
      if (e.code === 'ArrowDown') {
        this.changeFocusedElement(1);
      } else if (e.code === 'ArrowUp') {
        this.changeFocusedElement(-1);
      }
    });
  }

  destroyed() {
    this.sockets.unsubscribe('read');
    this.sockets.unsubscribe('message');
  }

  clickHandler(event?: Event) {
    if (event) {
      const target = event.target as HTMLElement;
      if (!target.className.includes('autosuggest-input')) {
        this.showAutoSuggest = false;
        this.showLastSearch = false;
      }
    } else {
      this.showAutoSuggest = false;
      this.showLastSearch = false;
    }
  }
  onSearchClick() {
    this.search = true;
    this.$nextTick(() => (this.$refs.searchInput as HTMLInputElement).focus());
    this.showLastSearch = true;
    this.lastSearchCount = this.lastSearch.length;
    vxm.search.saveLastSearch();
  }
  escapeSearch() {
    this.showAutoSuggest = false;
    this.showLastSearch = false;
    this.search = false;
  }
  onInput(e: Event) {
    this.searchInput = (e.target as HTMLInputElement).value;
    if (this.searchInput === '') {
      this.showLastSearch = true;
      this.showAutoSuggest = false;
    } else {
      this.showLastSearch = false;
      this.showAutoSuggest = true;
    }
  }
  onInputClick() {
    if (this.lastSearch.length) {
      this.showLastSearch = true;
    }
  }
  getAvatar(index: number) {
    return this.lastSearch[index].mediaId
      ? this.lastSearch[index].mediaId.link.xs
      : require('@/assets/icons/avatar-default.svg');
  }
  goToSearchResults(): void {
    if (!this.searchInput) {
      return;
    }
    this.showAutoSuggest = false;
    this.showLastSearch = false;
    vxm.search.setLastSearch(this.searchInput);
    const routeName = this.$route.name as string;
    if (this.$route.params.token === this.searchInput) {
      this.$router.go(0);
    }
    if (this.routeList.includes(routeName)) {
      this.$router.push({name: routeName, params: {token: this.searchInput}});
    } else {
      this.$router.push({name: 'popular', params: {token: this.searchInput}});
    }
  }
  searchBySuggestion(suggestion: {username: string}): void {
    if (suggestion) {
      this.showAutoSuggest = false;
      this.showLastSearch = false;
      this.$router.push({name: 'popular', params: {token: suggestion.username}});
    }
  }
  hideAutosuggest(): void {
    const input = this.$el.getElementsByClassName('autosuggest-input')[0] as HTMLElement;
    if (document.activeElement !== input) {
      input.focus();
    }
    this.showAutoSuggest = false;
  }
  onHaveSuggestions(suggestionsFound: boolean): void {
    this.haveSuggestions = suggestionsFound;
    this.showAutoSuggest = suggestionsFound;
  }
  clearSearch(): void {
    vxm.search.clearLastSearch();
  }
  cancelSearch() {
    this.search = false;
    this.searchInput = '';
  }
  changeFocusedElement(changeBy: number): void {
    const suggestions = this.$el.getElementsByClassName('autosuggest-suggestion') as HTMLCollectionOf<HTMLElement>;
    if (!suggestions.length || !document.activeElement) {
      return;
    }
    const input = this.$el.getElementsByClassName('autosuggest-input')[0] as HTMLElement;
    if (changeBy === 1) {
      if (document.activeElement === input) {
        if (this.showAutoSuggest) {
          this.focusedSuggestionIndex = 0;
          suggestions[this.focusedSuggestionIndex].focus();
        } else {
          this.showAutoSuggest = true;
        }
      } else if (this.focusedSuggestionIndex === suggestions.length - 1) {
        input.focus();
      } else if (document.activeElement.classList.contains('autosuggest-suggestion')) {
        suggestions[(this.focusedSuggestionIndex += 1)].focus();
      }
      return;
    }
    if (changeBy === -1) {
      if (document.activeElement === input) {
        if (this.showAutoSuggest) {
          this.focusedSuggestionIndex = suggestions.length - 1;
          suggestions[this.focusedSuggestionIndex].focus();
        } else {
          this.showAutoSuggest = true;
        }
      } else if (this.focusedSuggestionIndex === 0) {
        input.focus();
      } else if (document.activeElement.classList.contains('autosuggest-suggestion')) {
        suggestions[(this.focusedSuggestionIndex -= 1)].focus();
      }
    }
  }
  scrollToTop(): void {
    this.$scrollTo('body');
  }
  goHome() {
    if (this.$route.name === 'landing') {
      this.scrollToTop();
    } else if (this.$route.name === 'home') {
      this.$router.go(0);
    } else {
      this.$router.push({name: 'landing'});
    }
  }
}
