import api from './api';
import {SignUpDataInterface} from '@/types/SignUpDataInterface';

export default {
  signIn(data: {email: string; password: string}) {
    return api.post('/auth/sign-in', data);
  },
  signUpVerify(data: {email: string; 'g-recaptcha-response': string}) {
    return api.post('/auth/sign-up/verify', data);
  },
  signUpVerifyEmail(data: {email: string; inviteToken: string}) {
    return api.post('/auth/sign-up/verify-invite', data);
  },
  signUp(payload: {data: SignUpDataInterface; accessToken: string}) {
    return api.post('/auth/sign-up', payload.data, {
      headers: {
        Authorization: `Bearer ${payload.accessToken}`,
      },
    });
  },
  googleAuth() {
    return api.get('/google/redirect');
  },
  facebookAuth() {
    return api.get('/facebook/redirect');
  },
  refreshToken(token: string) {
    return api.post(
      '/auth/refresh-token',
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  verify(email: string) {
    return api.put('/auth/verify', {email: email});
  },
  logOut(token: string) {
    return api.delete('/auth/logout', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  logOutAll() {
    return api.delete('/auth/logout-all');
  },
  getToken() {
    return api.get('/auth/token');
  },
  requestChangePassword(email: string) {
    return api.post('/auth/reset-password', {email: email});
  },
  changePassword(payload: {data: {password: string; password2: string}; token: string}) {
    return api.put('/auth/reset-password', payload.data, {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
    });
  },
  checkUsernameUniqueness(username: string) {
    return api.post('/auth/is-unique', {username});
  },
};
