








import {Component, Vue} from 'vue-property-decorator';
import {vxm} from '@/store';
import ProfileActivityPreferencesModal from '@/components/modals/ProfileActivityPreferencesModal.vue';

@Component({
  components: {ProfileActivityPreferencesModal},
})
export default class CreatePostFloatingButton extends Vue {
  showProfileActivityPreferencesModal = false;

  openNewPostModal() {
    if (vxm.user.postPreferencesUnset) {
      this.showProfileActivityPreferencesModal = true;
      return;
    }
    vxm.post.setShowNewPostModal(true);
  }
  closeProfileActivityPreferencesModal(): void {
    this.showProfileActivityPreferencesModal = false;
  }
  onUserUpdated(): void {
    this.showProfileActivityPreferencesModal = false;
    this.openNewPostModal();
  }
}
