







import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class BhInput extends Vue {
  isActive = false;
  @Prop(String) readonly value!: string;
  @Prop(String) readonly placeholder!: string;
  @Prop(String) readonly type!: string;
  @Prop(String) readonly errorMsg!: string;
  @Prop(Boolean) readonly error!: boolean;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;

  get inputVal() {
    return this.value;
  }

  set inputVal(value) {
    this.$emit('input', value);
  }
}
