const videoTypesToTrim = ['video/mp4', 'video/webm'] as string[];

const videoTypesUntrimmable = [
  'video/mov',
  'video/avi',
  'video/m4v',
  'video/quicktime',
  'video/x-msvideo',
  '.hevc',
  '.avi',
  '.mov',
] as string[];

const videoTypes = videoTypesToTrim.concat(videoTypesUntrimmable);

export {videoTypesToTrim, videoTypesUntrimmable, videoTypes};
