







import {Component, Mixins} from 'vue-property-decorator';
import CreateAndEditPost from '@/views/CreateAndEditPost.vue';
import clickInside from '@/utils/clickInside';
import NoScrollMixin from '@/mixins/NoScrollMixin';

@Component({
  components: {CreateAndEditPost},
  directives: {
    clickInside,
  },
})
export default class NewPost extends Mixins(NoScrollMixin) {
  closeModal(): void {
    this.$emit('closeModal');
  }
}
