import {RouteConfig} from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    path: '',
    name: 'landing',
    component: () => import(/* webpackChunkName: "landing" */ '@/views/Landing.vue'),
    meta: {title: 'Bllhrn', isLogin: true},
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    meta: {title: 'Bllhrn | Login', isLogin: true},
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => import(/* webpackChunkName: "sign-up" */ '@/views/SignUp.vue'),
    meta: {title: 'Bllhrn | Sign Up', isLogin: true},
  },
  {
    path: '/sign-up/:token',
    name: 'sign-up-token',
    component: () => import(/* webpackChunkName: "sign-up" */ '@/views/SignUp.vue'),
    meta: {title: 'Bllhrn | Sign Up', isLogin: true},
  },
  {
    path: '/restore-password',
    name: 'restore-password',
    component: () => import(/* webpackChunkName: "restore-password" */ '@/views/RestorePassword.vue'),
    meta: {title: 'Bllhrn | Restore Password', isLogin: true},
  },
  {
    path: '/restore-password/:token',
    name: 'restore-password-token',
    component: () => import(/* webpackChunkName: "restore-password" */ '@/views/RestorePassword.vue'),
    meta: {title: 'Bllhrn | Restore Password', isLogin: true},
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    meta: {title: 'Bllhrn | Home', isAuth: true},
  },
  {
    path: '/explore',
    name: 'explore',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Explore.vue'),
    meta: {title: 'Bllhrn | Explore', isAuth: true},
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import(/* webpackChunkName: "chat" */ '@/views/Chat.vue'),
    meta: {title: 'Bllhrn | Chat', isAuth: true},
  },
  {
    path: '/search/:token',
    name: 'search',
    redirect: {name: 'popular'},
    component: () => import(/* webpackChunkName: "search" */ '@/views/Search.vue'),
    meta: {title: 'Bllhrn | Search', isAuth: true},
    children: [
      {
        path: 'popular',
        name: 'popular',
        component: () => import(/* webpackChunkName: "popular" */ '@/components/search/Popular.vue'),
        meta: {title: 'Bllhrn | Popular', isAuth: true},
      },
      {
        path: 'latest',
        name: 'latest',
        component: () => import(/* webpackChunkName: "latest" */ '@/components/search/Latest.vue'),
        meta: {title: 'Bllhrn | Latest', isAuth: true},
      },
      {
        path: 'people',
        name: 'people',
        component: () => import(/* webpackChunkName: "people" */ '@/components/search/People.vue'),
        meta: {title: 'Bllhrn | People', isAuth: true},
      },
      {
        path: 'photo',
        name: 'photo',
        component: () => import(/* webpackChunkName: "photo" */ '@/components/search/Photo.vue'),
        meta: {title: 'Bllhrn | Photo', isAuth: true},
      },
      {
        path: 'video',
        name: 'video',
        component: () => import(/* webpackChunkName: "video" */ '@/components/search/Video.vue'),
        meta: {title: 'Bllhrn | Video', isAuth: true},
      },
    ],
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import(/* webpackChunkName: "notifications" */ '@/views/Notifications.vue'),
    meta: {title: 'Bllhrn | Notifications', isAuth: true},
  },
  {
    path: '/license',
    name: 'license',
    component: () => import(/* webpackChunkName: "license" */ '@/views/License.vue'),
    meta: {title: 'Bllhrn | License'},
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "privacy-policy" */ '@/views/PrivacyPolicy.vue'),
    meta: {title: 'Bllhrn | Privacy Policy'},
  },
  {
    path: '/help-center',
    name: 'help-center',
    component: () => import(/* webpackChunkName: "help-center" */ '@/views/HelpCenter.vue'),
    meta: {title: 'Bllhrn | Help Center'},
  },
  {
    path: '/favorites',
    name: 'favorites',
    component: () => import(/* webpackChunkName: "favorites" */ '@/views/Favorites.vue'),
    meta: {title: 'Bllhrn | Favorites', isAuth: true},
  },
  {
    path: '/create-post',
    name: 'create-post',
    component: () => import(/* webpackChunkName: "create-post" */ '@/views/CreateAndEditPost.vue'),
    meta: {title: 'Bllhrn | Create New Post', isAuth: true},
  },
  {
    path: '/invitations',
    name: 'invitations',
    component: () => import(/* webpackChunkName: "invitations" */ '@/views/Invitations.vue'),
    meta: {title: 'Bllhrn | Invitations', isAuth: true},
  },
  {
    path: '/:profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue'),
    meta: {title: 'Bllhrn | Profile', isAuth: true},
    children: [
      {
        path: ':id',
        name: 'post',
        component: () => import(/* webpackChunkName: "profile" */ '@/components/modals/PostModal.vue'),
        meta: {title: 'Bllhrn | Followers', isAuth: true},
      },
      {
        path: 'followers',
        name: 'followers',
        component: () => import(/* webpackChunkName: "followers" */ '@/components/modals/Followers.vue'),
        meta: {title: 'Bllhrn | Followers', isAuth: true},
      },
      {
        path: 'following',
        name: 'following',
        component: () => import(/* webpackChunkName: "followers" */ '@/components/modals/Followers.vue'),
        meta: {title: 'Bllhrn | Following', isAuth: true},
      },
    ],
  },
];

export default routes;
