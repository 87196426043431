import api from './api';

export default {
  newPost(data: {text: string; media: string[]}) {
    return api.post('/posts', data);
  },
  updatePost(id: string, data: {text: string; media: string[]}) {
    return api.put(`/posts/${id}`, data);
  },
  deletePost(id: string) {
    return api.delete(`/posts/${id}`);
  },
  getPost(id: string) {
    return api.get(`/posts/${id}`);
  },
  repost(id: string) {
    return api.post(`/posts/repost/${id}`);
  },
  getComments(postId: string) {
    return api.get(`/comments?postId=${postId}`);
  },
  addComment(payload: {postId: string; text: string}) {
    return api.post('/comments', payload);
  },
  editComment(payload: {id: string; text: string}) {
    return api.put(`/comments/${payload.id}`, {
      text: payload.text,
    });
  },
  deleteComment(id: string) {
    return api.delete(`/comments/${id}`);
  },
  getSubcomments(payload: {postId: string; parentId: string}) {
    return api.get(`/subComments?postId=${payload.postId}&parentId=${payload.parentId}`);
  },
  addSubcomment(payload: {postId: string; parentId: string; mainId: string; text: string}) {
    return api.post('/subComments', payload);
  },
  editSubcomment(payload: {id: string; text: string}) {
    return api.put(`/subComments/${payload.id}`, {
      text: payload.text,
    });
  },
  deleteSubcomment(id: string) {
    return api.delete(`/subComments/${id}`);
  },
  report(payload: {postId: string; type: string}) {
    return api.post('/reports/posts', payload);
  },
};
