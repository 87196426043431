import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/routes';

import {checkAccessMiddleware, setPageTitleMiddleware, searchHashTags} from './middlewares';
Vue.use(VueRouter);

const router = new VueRouter({
  linkActiveClass: 'active',
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({x: 0, y: 0});
        }
      }, 150);
    });
  },
});

router.beforeEach(checkAccessMiddleware);
router.beforeEach(setPageTitleMiddleware);
router.beforeEach(searchHashTags);

export default router;
