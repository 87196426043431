import {action, getter, Module, mutation, VuexModule} from 'vuex-class-component';

@Module({namespacedPath: 'files/'})
export class FilesStore extends VuexModule {
  @getter files = {} as FileList;
  @getter file = {} as File;
  @getter showUploadErrorModal = false;
  @getter uploadErrorActionResolve: Function | null = null;
  @getter uploadErrorAction: Promise<any> | null = null;
  @getter abortUpload = false;

  @mutation setShowUploadErrorModal(data: boolean) {
    this.showUploadErrorModal = data;
    if (data) {
      this.uploadErrorAction = new Promise((resolve) => {
        this.uploadErrorActionResolve = resolve;
      });
    }
  }

  @mutation setAbortUpload(data: boolean) {
    this.abortUpload = data;
  }

  @mutation setFiles(files: FileList) {
    this.files = files;
  }

  @mutation deleteFiles() {
    this.files = {} as FileList;
  }

  @mutation setFile(file: File) {
    this.file = file;
  }

  @mutation deleteFile() {
    this.file = {} as File;
  }

  @action
  async addFiles(files: FileList) {
    return this.setFiles(files);
  }

  @action
  async removeFiles() {
    return this.deleteFiles();
  }

  @action
  async addFile(file: File) {
    return this.setFile(file);
  }

  @action
  async removeFile() {
    return this.deleteFile();
  }
}
