











import {Component, Vue} from 'vue-property-decorator';
import UploadProgressBar from '@/components/UploadProgressBar.vue';
import {vxm} from '@/store';

@Component({
  components: {UploadProgressBar},
})
export default class UploadingProgress extends Vue {
  mounted() {
    window.onbeforeunload = function() {
      return 'You have unsaved changes!';
    };
  }
  beforeDestroy() {
    window.onbeforeunload = null;
  }
  get uploadingState(): {id: string; progress: number; blobs: string[]; media: File[]}[] {
    return vxm.post.uploadingState;
  }
  setBackground(url: string): {} {
    return {
      background: `url(${url}) center/cover no-repeat`,
    };
  }
}
